import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { AlertService } from '@ppgt/web/shared/core';
import { AppState } from '@app/state';

import {
  AssignEmployeeBody,
  Building,
  CheckedDeliveries,
  Construction,
  ConstructionCrew,
  ConstructionPlanDimensions,
  Contract,
  CreateInspectionBody,
  CreateInspectionResponse,
  CubeTestsPurpose,
  Dashboard,
  DashboardCanteen,
  DashboardPreview,
  DashboardTV,
  Delivery,
  DeliveryComment,
  DeliveryContacts,
  DeliveryFile,
  DeliveryLongterm,
  DeliveryLongtime,
  DeliveryMaterial,
  DeliveryPourCard,
  DeliveryRun,
  DeliverySetTimesBody,
  DeliverySettings,
  DeliveryUnloadingMethod,
  Depot,
  Distributor,
  EcologySettings,
  Elevator,
  Equipment,
  EquipmentBrand,
  EquipmentDeliveriesDetails,
  EquipmentModel,
  EquipmentReservation,
  EquipmentTimes,
  EquipmentType,
  Gate,
  GeneralSettings,
  GetDeliveryLogsResponse,
  GetLongtermDeliveryTermsPayload,
  InspectionStatus,
  Log,
  LongtermDeliveryTerm,
  Material,
  MaterialCategory,
  MaterialDestination,
  MaterialPackingMethod,
  MaterialProducer,
  MaterialsComment,
  MaterialsDescription,
  MaterialSupplier,
  Notif,
  ObjectSettings,
  ProjectSettings,
  Propose,
  RejectReason,
  Report,
  ReportQueries,
  ScheduleColorsSet,
  SendOnetimeDeliveryTicketImagesBody,
  Subcontractor,
  SubcontractorLocation,
  SubcontractorsEmployee,
  SubcontractorsEmployeeRole,
  SubcontractorSettings,
  Unit,
  UnloadingPlace,
  UnloadingUtility,
  User,
  Vehicle,
  VehicleObj,
  VehicleSize,
} from '@app/state/interfaces';
import { ObjectToDeactivate, SupplierLocation } from '@interfaces';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { identity, pickBy } from 'lodash-es';
import * as moment from 'moment-timezone';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorsNames } from '../../shared/@errors/error-names';
import { ErrorHandlerService } from './error-handler.service';

import { createApiUrl, createQueryParams } from './http-utils';
// Interfaces
import {
  CancelDeliveryRes,
  CheckDeliveryReq,
  CheckDeliveryRes,
  CheckInDeliveryReq,
  CheckOutDeliveryReq,
  ConfirmAccountReq,
  ConfirmAccountRes,
  CreateBuildingReq,
  CreateBuildingRes,
  CreateConstructionReq,
  CreateContractReq,
  CreateContractRes,
  CreateDeliveryReq,
  CreateDepotsReq,
  CreateDepotsRes,
  CreateEquipmentReq,
  CreateEquipmentRes,
  CreateGateReq,
  CreateGateRes,
  CreateMaterialReq,
  CreatePlaceReq,
  CreatePlaceRes,
  CreateSubcontractorBody,
  CreateUserReq,
  CreateVehiclesReq,
  DeleteDepotRes,
  DeleteEquipmentRes,
  DeleteGateRes,
  DeletePlaceRes,
  GetBuildingRes,
  GetBuildingsRes,
  GetConstructionRes,
  GetConstructionsRes,
  GetContractRes,
  GetContractsRes,
  GetCurrentConstructionRes,
  GetDashboardRes,
  GetDeliveriesRes,
  GetDeliveryRes,
  GetDeliverySettingsRes,
  GetDepotRes,
  GetDepotsRes,
  GetEcologySettingsRes,
  GetEquipmentRes,
  GetFutureConstructionRes,
  GetGateRes,
  GetGatesRes,
  GetGeneralSettingsRes,
  GetInspectionStatusesResponse,
  GetManualModeDeliveriesReq,
  GetManualModeDeliveriesRes,
  GetMaterialCategoriesRes,
  GetMaterialCategoryRes,
  GetMaterialsRes,
  GetObjectSettingsRes,
  GetPlaceRes,
  GetPlacesRes,
  GetProducersRes,
  GetProjectSettingsRes,
  GetReportRes,
  GetReportsRes,
  GetSubcontractorPlacesRes,
  GetSubcontractorRes,
  GetSubcontractorSettingsRes,
  GetSubcontractorsRes,
  GetSubcontractorVehiclesRes,
  GetSuppliersRes,
  GetUserRes,
  GetUsersRes,
  GetVehiclesRes,
  IUpdateDelivery,
  ListResponse,
  NewPasswordReq,
  ProposeDeliveryReq,
  ProposeDeliveryRes,
  QueryParamObject,
  QueryParams,
  RejectDeliveryRes,
  Response,
  ResponseMetadata,
  SendManualModeDeliveriesReq,
  SendManualModeDeliveriesRes,
  SendOrdersReq,
  SendReportReq,
  TranslationsRes,
  UpdateAvatarFile,
  UpdateConstructionPlanReq,
  UpdateConstructionPlanRes,
  UpdateConstructionPlanSuccessRes,
  UpdateConstructionReq,
  UpdateConstructionRes,
  UpdateContractReq,
  UpdateDeliveryFilesReq,
  UpdateDeliveryMaterialReq,
  UpdateDeliveryRes,
  UpdateDepotsReq,
  UpdateSubcontractorReq,
  UpdateUserReq,
  UserLoginReq,
  UserLoginRes,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly USER_AUDIT_FILENAME = 'propergate-audit.log';

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    public alertService: AlertService,
    public store: Store<AppState>
  ) {}

  errorShowAndHandle = (httpError: HttpErrorResponse): Observable<any> =>
    this.errorHandlerService.errorShowAndHandle(httpError);

  login(userData: UserLoginReq): Observable<UserLoginRes['data']> {
    return this.http.post<UserLoginRes>(createApiUrl('users/login'), userData).pipe(
      map((response) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  confirmAccount(accountData: ConfirmAccountReq, setPasswordToken: string): Observable<ConfirmAccountRes> {
    const headers = new HttpHeaders({
      Authorization: setPasswordToken,
    });

    return this.http.put(createApiUrl('users/setup-password'), accountData, { headers }).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getRegulation(language: string): Observable<{ regulation: SafeHtml }> {
    const params = new HttpParams().set('language', language);

    return this.http.get<{ regulation: SafeHtml }>(createApiUrl('regulations'), { params });
  }

  getUsers(params: QueryParamObject[] = null): Observable<GetUsersRes> {
    return this.http.get(createApiUrl(`users${createQueryParams(params)}`)).pipe(
      map((response: Response<GetUsersRes>) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getUser(id: string): Observable<User> {
    return this.http.get(createApiUrl(`users/${id}`)).pipe(
      map((response: Response<{ user: User }>) => response.data.user),
      catchError(this.errorShowAndHandle)
    );
  }

  getMe(): Observable<User> {
    return this.http.get(createApiUrl('users/get/me')).pipe(
      map((response: GetUserRes) => response.data.user),
      catchError(this.errorShowAndHandle)
    );
  }

  refreshToken(id: string): Observable<string> {
    return this.http.get(createApiUrl(`users/${id}/refresh-token`)).pipe(catchError(this.errorShowAndHandle));
  }

  createUser(userData: CreateUserReq): Observable<User> {
    return this.http.post(createApiUrl('users'), userData).pipe(
      map((response: Response<{ user: User }>) => response.data.user),
      catchError((httpError): Observable<any> => {
        if (httpError.error.message === ErrorsNames.USER_SIMILAR_EMAIL) {
          this.alertService.showError('settings_page.user_exist_l'); // exception or this key
        }
        this.errorShowAndHandle(httpError);
        return throwError(httpError);
      })
    );
  }

  updateUser(user: UpdateUserReq): Observable<User> {
    const { id, avatar, ...userData } = user;

    return this.http.put(createApiUrl(`users/${id}`), userData).pipe(
      map((response: Response<{ user: User }>) => response.data.user),
      catchError(this.errorShowAndHandle)
    );
  }

  updatePassword({ userId, email }: { userId: string; email: string }): any {
    return this.http.post(createApiUrl(`users/${userId}/password`), { email }).pipe(
      map((res: any) => res),
      catchError(this.errorShowAndHandle)
    );
  }

  newPassword(userData: NewPasswordReq): any {
    return this.http.put(createApiUrl(`users/${userData.id}/password`), { ...userData }).pipe(
      map((res: any) => res.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getLogs() {
    return this.http.get(createApiUrl('logs'), { responseType: 'blob' }).pipe(
      map((res: Blob) => {
        saveAs(res, this.USER_AUDIT_FILENAME);
      }),
      catchError(this.errorShowAndHandle)
    );
  }

  getSubcontractorsEmployees(subcontractorId: string): Observable<SubcontractorsEmployee[]> {
    return this.http.get<SubcontractorsEmployee[]>(createApiUrl(`subcontractors/${subcontractorId}/employees`));
  }

  getUserPin(userId: string): Observable<string> {
    return this.http.get<string>(createApiUrl(`users/${userId}/pin`)).pipe(catchError(this.errorShowAndHandle));
  }

  createSubcontractorsEmployee(employee: SubcontractorsEmployee): Observable<User> {
    return this.http
      .post<Response<{ user: User }>>(createApiUrl('users'), employee)
      .pipe(map((response) => response.data.user));
  }

  updateSubcontractorsEmployee(employee: SubcontractorsEmployee, subcontractorId: string): Observable<User> {
    return this.http.put<User>(createApiUrl(`subcontractors/${subcontractorId}/employees/${employee.id}`), employee);
  }

  assignEmployeeToSubcontractor(
    subcontractorId: string,
    employeeId: string,
    body: AssignEmployeeBody
  ): Observable<SubcontractorsEmployee> {
    return this.http.post<SubcontractorsEmployee>(
      createApiUrl(`subcontractors/${subcontractorId}/employee/${employeeId}/assign`),
      body
    );
  }

  getSubcontractorsEmployeeRoles(): Observable<SubcontractorsEmployeeRole[]> {
    return this.http.get<SubcontractorsEmployeeRole[]>(createApiUrl('subcontractors-roles'));
  }

  getSubcontractorsSuppliers(id: string): Observable<MaterialSupplier[]> {
    return this.http.get<MaterialSupplier[]>(createApiUrl(`subcontractors/${id}/suppliers`));
  }

  getSubcontractorLocations(id: string): Observable<SupplierLocation[]> {
    return this.http.get<SupplierLocation[]>(createApiUrl(`subcontractors/${id}/suppliers/locations`));
  }

  getNotifications(): Observable<Notif[]> {
    return this.http.get(createApiUrl('notifications')).pipe(
      map((res: ListResponse<Notif[]>) => res.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateNotification(notificationId: string): Observable<Notif> {
    return this.http.put(createApiUrl('notifications'), { notificationId }).pipe(
      map((res: Response<Notif>) => res.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateNotifications(): Observable<void> {
    return this.http.put(createApiUrl('notifications/seen-all'), {}).pipe(
      map((res: any) => res.data),
      catchError(this.errorShowAndHandle)
    );
  }

  editUserTheme({ theme, userId }: { theme: string; userId: string }): Observable<any> {
    return this.http.put(createApiUrl(`users/${userId}`), { theme }).pipe(
      map((response: GetUserRes) => response.data.user),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /translations

  getTranslations(): Observable<{ [key: string]: { [key: string]: string } }> {
    return this.http.get<TranslationsRes>(createApiUrl('translations')).pipe(
      map((response) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /construction-site

  getConstructions(): Observable<Construction[]> {
    return this.http.get(createApiUrl('construction-site')).pipe(
      map((response: GetConstructionsRes) => response.data.constructionSites),
      catchError(this.errorShowAndHandle)
    );
  }

  createConstruction(construction: CreateConstructionReq): Observable<any> {
    // add interface
    return this.http.post(createApiUrl('construction-site'), construction).pipe(
      map((response: CreateContractRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getConstruction(construction: { id: string }): Observable<Construction> {
    return this.http.get(createApiUrl(`construction-site/${construction.id}`)).pipe(
      map((response: GetConstructionRes) => response.data.constructionSite),
      catchError(this.errorShowAndHandle)
    );
  }

  getCurrentConstruction(): Observable<Construction> {
    return this.http.get(createApiUrl('construction-site/site/current')).pipe(
      map((response: GetCurrentConstructionRes) => response.data.currentConstructionSite),
      catchError(this.errorShowAndHandle)
    );
  }

  getFutureConstruction(): Observable<Construction> {
    return this.http.get(createApiUrl('construction-site/site/future')).pipe(
      map((response: GetFutureConstructionRes) => response.data.futureConstructionSite),
      catchError(this.errorShowAndHandle)
    );
  }

  updateConstruction(construction: UpdateConstructionReq): Observable<Construction> {
    return this.http.put(createApiUrl(`construction-site/${construction.id}`), construction).pipe(
      map((response: UpdateConstructionRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateConstructionPlan(data: UpdateConstructionPlanReq): Observable<UpdateConstructionPlanSuccessRes> {
    return this.http.put(createApiUrl(`construction-site/${data.id}/background`), data.formData).pipe(
      map((response: UpdateConstructionPlanRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  deleteConstructionPlan(id: string): Observable<any> {
    return this.http
      .delete(createApiUrl(`construction-site/${id}/background`))
      .pipe(catchError(this.errorShowAndHandle));
  }

  removeConstruction(construction: { id: string }): Observable<CreateContractRes> {
    return this.http.delete(createApiUrl(`construction-site/${construction.id}`)).pipe(
      map((response: CreateContractRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  updatePlanDimensions(dimensions: ConstructionPlanDimensions): Observable<ConstructionPlanDimensions> {
    return this.http
      .put(createApiUrl(`construction-site/dimensions`), dimensions)
      .pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // /construction-site/:id/objects

  getConstructionObjects(object: { id: string }): Observable<any> {
    // add interface
    return this.http.get(createApiUrl(`construction-site/${object.id}/objects`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  createConstructionObject(object: any): Observable<any> {
    // add interface
    return this.http.post(createApiUrl(`construction-site/${object.id}/objects`), object).pipe(
      map((response: CreateContractRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getConstructionObject(object: { constrId: string; objectId: string }): Observable<any> {
    // add interface
    return this.http.get(createApiUrl(`construction-site/${object.constrId}/objects/${object.objectId}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  updateConstructionObject(object: any): Observable<any> {
    // add interface
    return this.http.put(createApiUrl(`construction-site/${object.constrId}/objects/${object.objectId}`), object).pipe(
      map((response: CreateContractRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  removeConstructionObject(object: { constrId: string; objectId: string }): Observable<any> {
    // add interface
    return this.http.delete(createApiUrl(`construction-site/${object.constrId}/objects/${object.objectId}`)).pipe(
      map((response: CreateContractRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  deactivateObjects(objects: ObjectToDeactivate[]): Observable<any> {
    return this.http
      .post(createApiUrl('construction-site/deactivate-objects'), objects)
      .pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // /contracts

  getContracts(params: QueryParamObject[] = null): Observable<GetContractsRes> {
    return this.http.get(createApiUrl(`contracts${createQueryParams(params)}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getContract(data: { id: string }): Observable<Contract> {
    return this.http.get(createApiUrl(`contracts/${data.id}`)).pipe(
      map((response: GetContractRes) => response.data.contract),
      catchError(this.errorShowAndHandle)
    );
  }

  createContract(contract: CreateContractReq): Observable<Contract> {
    return this.http.post(createApiUrl('contracts'), contract).pipe(
      map((response: { data: Contract }) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateContract(contract: UpdateContractReq): Observable<GetContractsRes> {
    return this.http.put(createApiUrl(`contracts/${contract.id}`), contract).pipe(
      map((response: CreateContractRes) => response.data.contract),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /construction crews

  getConstructionCrews(subcontractorId: string): Observable<ConstructionCrew> {
    return this.http
      .get(createApiUrl(`subcontractors/${subcontractorId}/crews`))
      .pipe(catchError(this.errorShowAndHandle));
  }

  getConstructionCrew(subcontractorId: string, crewId: string): Observable<ConstructionCrew> {
    return this.http.get(createApiUrl(`subcontractors/${subcontractorId}/crews/${crewId}`)).pipe(
      map((response: Response<{ constructionCrew: ConstructionCrew }>) => response.data.constructionCrew),
      catchError(this.errorShowAndHandle)
    );
  }

  createConstructionCrew(subcontractorId: string, crewData: Partial<ConstructionCrew>): Observable<ConstructionCrew> {
    return this.http
      .post(createApiUrl(`subcontractors/${subcontractorId}/crews`), crewData)
      .pipe(catchError(this.errorShowAndHandle));
  }

  updateConstructionCrew(
    subcontractorId: string,
    crewId: string,
    crewData: Partial<ConstructionCrew>
  ): Observable<ConstructionCrew> {
    return this.http
      .patch(createApiUrl(`subcontractors/${subcontractorId}/crews/${crewId}`), crewData)
      .pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // OBJECTS

  // ------------------------------------------------------------------------------------
  // buildings

  getBuildings(params: QueryParamObject[] = null): Observable<Building[]> {
    return this.http.get(createApiUrl(`buildings${createQueryParams(params)}`)).pipe(
      map((response: GetBuildingsRes) => response.data.buildings),
      catchError(this.errorShowAndHandle)
    );
  }

  getBuilding(building: { id: string }): Observable<Building> {
    return this.http.get(createApiUrl(`buildings/${building.id}`)).pipe(
      map((response: GetBuildingRes) => response.data.building),
      catchError(this.errorShowAndHandle)
    );
  }

  createBuilding(building: CreateBuildingReq): Observable<Building> {
    return this.http.post(createApiUrl('buildings'), building).pipe(
      map((response: CreateBuildingRes) => response.data.building),
      catchError(this.errorShowAndHandle)
    );
  }

  updateBuilding(building: { id: string; data: CreateBuildingReq }): Observable<Building> {
    return this.http.put(createApiUrl(`buildings/${building.id}`), building.data).pipe(
      map((response: CreateBuildingRes) => response.data.building),
      catchError(this.errorShowAndHandle)
    );
  }

  removeBuilding(building: { id: string }): Observable<any> {
    return this.http.delete(createApiUrl(`buildings/${building.id}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getDepots(params: QueryParamObject[] = null): Observable<Depot[]> {
    return this.http.get(createApiUrl(`depots${createQueryParams(params)}`)).pipe(
      map((response: GetDepotsRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getMaterialDepots(categoryId: string): Observable<Depot[]> {
    return this.http
      .get(createApiUrl(`materials/categories/${categoryId}/depots`))
      .pipe(catchError(this.errorShowAndHandle));
  }

  getDepot(depot: { id: string }): Observable<Depot> {
    return this.http.get(createApiUrl(`depots/${depot.id}`)).pipe(
      map((response: GetDepotRes) => response.data.depot),
      catchError(this.errorShowAndHandle)
    );
  }

  createDepot(depot: CreateDepotsReq): Observable<Depot> {
    return this.http.post(createApiUrl('depots'), depot).pipe(
      map((response: CreateDepotsRes) => response.data.depot),
      catchError(this.errorShowAndHandle)
    );
  }

  updateDepot(depot: { id: string; data: UpdateDepotsReq }): Observable<Depot> {
    return this.http.put(createApiUrl(`depots/${depot.id}`), depot.data).pipe(
      map((response: CreateDepotsRes) => response.data.depot),
      catchError(this.errorShowAndHandle)
    );
  }

  removeDepot(depot: { id: string }): Observable<any> {
    return this.http.delete(createApiUrl(`depots/${depot.id}`)).pipe(
      map((response: DeleteDepotRes) => response.data.depot),
      catchError(this.errorShowAndHandle)
    );
  }

  getEquipment(params: QueryParamObject[] = null): Observable<Equipment[]> {
    return this.http.get(createApiUrl(`equipment${createQueryParams(params)}`)).pipe(
      map((response: GetEquipmentRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getSelectedEquipment(equipment: { id: string }): Observable<Equipment> {
    return this.http.get(createApiUrl(`equipment/${equipment.id}`)).pipe(
      map((response: GetEquipmentRes) => response.data.equipment),
      catchError(this.errorShowAndHandle)
    );
  }

  getEquipmentDeliveriesDetails(equipment: { id: string; type: string }): Observable<EquipmentDeliveriesDetails> {
    return this.http
      .get(createApiUrl(`objects/delivery-details/${equipment.id}?type=${equipment.type}`))
      .pipe(catchError(this.errorShowAndHandle));
  }

  createEquipment(equipment: CreateEquipmentReq): Observable<Equipment> {
    return this.http.post(createApiUrl('equipment'), equipment).pipe(
      map((response: CreateEquipmentRes) => response.data.equipment),
      catchError(this.errorShowAndHandle)
    );
  }

  updateEquipment(equipment: { id: string; data: CreateEquipmentReq }): Observable<Equipment> {
    return this.http.put(createApiUrl(`equipment/${equipment.id}`), equipment.data).pipe(
      map((response: CreateEquipmentRes) => response.data.equipment),
      catchError(this.errorShowAndHandle)
    );
  }

  removeEquipment(equipment: { id: string }): Observable<any> {
    return this.http.delete(createApiUrl(`equipment/${equipment.id}`)).pipe(
      map((response: DeleteEquipmentRes) => response.data.equipment),
      catchError(this.errorShowAndHandle)
    );
  }

  createEquipmentTimes(equipmentId: string, times: Partial<EquipmentTimes[]>): Observable<EquipmentTimes> {
    return this.http.post<EquipmentTimes>(createApiUrl(`equipment/${equipmentId}/equipment-times`), times);
  }

  updateEquipmentTimes(equipmentId: string, time: Partial<EquipmentTimes>): Observable<EquipmentTimes> {
    return this.http.put<EquipmentTimes>(createApiUrl(`equipment/${equipmentId}/equipment-times/${time.id}`), time);
  }

  deleteEquipmentTime(equipmentId: string, timeId: string): Observable<EquipmentTimes> {
    return this.http.delete<EquipmentTimes>(createApiUrl(`equipment/${equipmentId}/equipment-times/${timeId}`));
  }

  getEquipmentTypes(): Observable<EquipmentType[]> {
    return this.http.get<EquipmentType[]>(createApiUrl('equipment/types'));
  }

  getUnloadingUtilities(): Observable<UnloadingUtility[]> {
    return this.http.get<UnloadingUtility[]>(createApiUrl('unloading-utilities'));
  }

  getEquipmentBrands(): Observable<EquipmentBrand[]> {
    return this.http.get<EquipmentBrand[]>(createApiUrl('equipment/brands'));
  }

  getEquipmentModels(): Observable<EquipmentModel[]> {
    return this.http.get<EquipmentModel[]>(createApiUrl('equipment/models'));
  }

  createEquipmentReservation(reservation: EquipmentReservation): Observable<EquipmentReservation> {
    return this.http
      .post<EquipmentReservation>(createApiUrl('reservations'), reservation)
      .pipe(catchError(this.errorShowAndHandle));
  }

  updateEquipmentReservation(id: string, reservation: EquipmentReservation): Observable<EquipmentReservation> {
    return this.http
      .put<EquipmentReservation>(createApiUrl(`reservations/${id}`), reservation)
      .pipe(catchError(this.errorShowAndHandle));
  }

  getEquipmentReservation(id: string): Observable<EquipmentReservation> {
    return this.http.get<EquipmentReservation>(createApiUrl(`reservations/${id}`));
  }

  deleteEquipmentReservation(id: string): Observable<{}> {
    return this.http.delete(createApiUrl(`reservations/${id}`));
  }

  getGates(params: QueryParamObject[] = null): Observable<Gate[]> {
    return this.http.get(createApiUrl(`gates${createQueryParams(params)}`)).pipe(
      map((response: GetGatesRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getGate(gate: { id: string }): Observable<Gate> {
    return this.http.get(createApiUrl(`gates/${gate.id}`)).pipe(
      map((response: GetGateRes) => response.data.gate),
      catchError(this.errorShowAndHandle)
    );
  }

  createGate(gate: CreateGateReq): Observable<Gate> {
    return this.http.post(createApiUrl('gates'), gate).pipe(
      map((response: CreateGateRes) => response.data.gate),
      catchError(this.errorShowAndHandle)
    );
  }

  updateGate(gate: { id: string; data: CreateGateReq }): Observable<Gate> {
    return this.http.put(createApiUrl(`gates/${gate.id}`), gate.data).pipe(
      map((response: CreateGateRes) => response.data.gate),
      catchError(this.errorShowAndHandle)
    );
  }

  removeGate(gate: { id: string }): Observable<any> {
    return this.http.delete(createApiUrl(`gates/${gate.id}`)).pipe(
      map((response: DeleteGateRes) => response.data.gate),
      catchError(this.errorShowAndHandle)
    );
  }

  getPlaces(params: QueryParamObject[] = null): Observable<UnloadingPlace[]> {
    return this.http.get(createApiUrl(`places${createQueryParams(params)}`)).pipe(
      map((response: GetPlacesRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getPlace(place: { id: string }): Observable<UnloadingPlace> {
    return this.http.get(createApiUrl(`places/${place.id}`)).pipe(
      map((response: GetPlaceRes) => response.data.place),
      catchError(this.errorShowAndHandle)
    );
  }

  createPlace(place: CreatePlaceReq): Observable<UnloadingPlace> {
    return this.http.post(createApiUrl('places'), place).pipe(
      map((response: CreatePlaceRes) => response.data.place),
      catchError(this.errorShowAndHandle)
    );
  }

  updatePlace(place: { id: string; data: CreatePlaceReq }): Observable<UnloadingPlace> {
    return this.http.put(createApiUrl(`places/${place.id}`), place.data).pipe(
      map((response: CreatePlaceRes) => response.data.place),
      catchError(this.errorShowAndHandle)
    );
  }

  removePlace(place: { id: string }): Observable<any> {
    return this.http.delete(createApiUrl(`places/${place.id}`)).pipe(
      map((response: DeletePlaceRes) => response.data.place),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /subcontractors

  getSubcontractors(params: QueryParamObject[] = null): Observable<GetSubcontractorsRes> {
    return this.http.get(createApiUrl(`subcontractors${createQueryParams(params)}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  createSubcontractor(body: CreateSubcontractorBody): Observable<Subcontractor> {
    return this.http.post(createApiUrl('subcontractors'), body).pipe(catchError(this.errorShowAndHandle));
  }

  updateSubcontractor({ subcontractor }: UpdateSubcontractorReq): Observable<Subcontractor> {
    return this.http.put(createApiUrl(`subcontractors/${subcontractor.id}`), subcontractor).pipe(
      map((response: GetSubcontractorRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateSubcontractorPlaces({
    subcontractorId,
    placesToUpdate,
  }: {
    subcontractorId: string;
    placesToUpdate: SubcontractorLocation[];
  }): Observable<GetSubcontractorPlacesRes>[] {
    return placesToUpdate.map((place) => this.updateSubcontractorPlace({ subcontractorId, place }));
  }

  updateSubcontractorPlace({
    subcontractorId,
    place,
  }: {
    subcontractorId: string;
    place: SubcontractorLocation;
  }): Observable<GetSubcontractorPlacesRes> {
    const { id: placeId, ...purePlace } = place;
    return this.http.put(createApiUrl(`subcontractors/${subcontractorId}/locations/${placeId}`), purePlace).pipe(
      map((response: GetSubcontractorPlacesRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  createSubcontractorPlaces({
    subcontractorId,
    placesToCreate,
  }: {
    subcontractorId: string;
    placesToCreate: SubcontractorLocation[];
  }): Observable<GetSubcontractorPlacesRes> {
    return this.http.post(createApiUrl(`subcontractors/${subcontractorId}/locations`), placesToCreate).pipe(
      map((response: GetSubcontractorPlacesRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  deleteSubcontractorPlaces({
    subcontractorId,
    placesToDelete,
  }: {
    subcontractorId: string;
    placesToDelete: SubcontractorLocation[];
  }): Observable<{ data: { place: number } }>[] {
    return placesToDelete.map((place) =>
      this.deleteSubcontractorPlace({
        subcontractorId,
        placeToDeleteId: place.id,
      })
    );
  }

  deleteSubcontractorPlace({
    subcontractorId,
    placeToDeleteId,
  }: {
    subcontractorId: string;
    placeToDeleteId: string;
  }): Observable<{ data: { place: number } }> {
    return this.http.delete(createApiUrl(`subcontractors/${subcontractorId}/locations/${placeToDeleteId}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getSubcontractor(subcontractor: { id: string }): Observable<GetSubcontractorRes> {
    return this.http.get(createApiUrl(`subcontractors/${subcontractor.id}`)).pipe(
      map((response) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /subcontractors/:id/contracts

  getSubcontractorContracts(payload: { id: string; queries: QueryParamObject[] }): Observable<GetContractsRes['data']> {
    const path = `subcontractors/${payload.id}/contracts${createQueryParams(payload.queries)}`;
    return this.http.get(createApiUrl(path)).pipe(
      map((response: GetContractsRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /vehicles

  getVehicles(): Observable<Vehicle[]> {
    return this.http.get(createApiUrl('vehicles')).pipe(
      map((response: GetVehiclesRes) => response.data.vehicles),
      catchError(this.errorShowAndHandle)
    );
  }

  createVehicles({ supplierId, vehicles }: CreateVehiclesReq): Observable<VehicleObj[]> {
    return this.http
      .post(createApiUrl(`suppliers/${supplierId}/vehicles`), { vehicles })
      .pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // /subcontractors/:id/vehicles

  getSubcontractorVehicles(payload: {
    id: string;
    queries?: QueryParamObject[];
  }): Observable<GetSubcontractorVehiclesRes> {
    return this.http
      .get(createApiUrl(`subcontractors/${payload.id}/suppliers/vehicles${createQueryParams(payload.queries)}`))
      .pipe(
        map((response: GetVehiclesRes) => response),
        catchError(this.errorShowAndHandle)
      );
  }

  // ------------------------------------------------------------------------------------
  // /subcontractors/:id/places

  getSubcontractorPlaces(subcontractor: { id: string }): Observable<SubcontractorLocation[]> {
    return this.http.get(createApiUrl(`subcontractors/${subcontractor.id}/locations`)).pipe(
      map((response: GetSubcontractorPlacesRes) => response.data.places),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /deliveries

  getDeliveries(params: QueryParamObject[] = null): Observable<GetDeliveriesRes['data']> {
    return this.http.get(createApiUrl(`deliveries${createQueryParams(params)}`)).pipe(
      map((response: GetDeliveriesRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getLongtermDeliveries(
    params: QueryParamObject[] = null,
    status: string
  ): Observable<{ deliveries: DeliveryLongterm[]; meta: ResponseMetadata }> {
    return this.http
      .get<{ deliveries: DeliveryLongterm[]; meta: ResponseMetadata }>(
        createApiUrl(`deliveries/longtime${createQueryParams(params)}&status=${status}`)
      )
      .pipe(catchError(this.errorShowAndHandle));
  }

  assignStageToRun(deliveryStageId: string, runId: string): Observable<any> {
    return this.http.put(createApiUrl(`deliveries-runs/${runId}`), { deliveryStageId });
  }

  setDeliveryRunsTimes(deliveryRunId: string, body: DeliverySetTimesBody): Observable<DeliveryRun> {
    return this.http.put<DeliveryRun>(createApiUrl(`deliveries-runs/${deliveryRunId}/times`), body);
  }

  sendTicketImage(deliveryFilesReqConfig: UpdateDeliveryFilesReq): Observable<DeliveryRun> {
    const { files, registrationNumber, deliveryRunId, deliveryId } = deliveryFilesReqConfig;

    const formData = new FormData();

    formData.append('type', 'deliveryNote');
    formData.append('deliveryRunId', deliveryRunId);
    formData.append('action', 'BY_SUB');
    formData.append('registrationNumber', registrationNumber);

    files.forEach((file) => {
      formData.append('files', file[0] || file);
    });

    return this.http.put<DeliveryRun>(createApiUrl(`deliveries/${deliveryId}/upload-files`), formData);
  }

  updateDeliveryRun(runId: string, data: Partial<DeliveryRun>): Observable<DeliveryRun> {
    return this.http.put<DeliveryRun>(createApiUrl(`deliveries-runs/${runId}`), data);
  }

  sendOnetimeDeliveryTicketImages(
    deliveryId: string,
    data: SendOnetimeDeliveryTicketImagesBody
  ): Observable<DeliveryFile[]> {
    const formData = new FormData();

    formData.append('type', data.type);
    formData.append('subcontractorId', data.subcontractorId);
    formData.append('materialId', data.materialId);

    data.files.forEach((file) => {
      formData.append('files', file);
    });

    return this.http.put<DeliveryFile[]>(createApiUrl(`deliveries/${deliveryId}/upload-files`), formData);
  }

  getCurrentTicketImageUrl(deliveryId: string, fileId: string): Observable<DeliveryFile> {
    return this.http.get<DeliveryFile>(createApiUrl(`deliveries/${deliveryId}/delivery-files/${fileId}`));
  }

  getPourCard(deliveryRunId: string): Observable<DeliveryPourCard> {
    return this.http
      .get<DeliveryPourCard>(createApiUrl(`deliveries-runs/${deliveryRunId}/pour-card`))
      .pipe(catchError(this.errorShowAndHandle));
  }

  getCubeTestsPurposes(): Observable<CubeTestsPurpose[]> {
    return this.http
      .get<CubeTestsPurpose[]>(createApiUrl('testing-purposes'))
      .pipe(catchError(this.errorShowAndHandle));
  }

  sendPourCard(deliveryRunId: string, data: DeliveryPourCard): Observable<DeliveryPourCard> {
    return this.http
      .post<DeliveryPourCard>(createApiUrl(`deliveries-runs/${deliveryRunId}/pour-card`), data)
      .pipe(catchError(this.errorShowAndHandle));
  }

  updatePourCard(deliveryRunId: string, data: DeliveryPourCard): Observable<DeliveryPourCard> {
    return this.http
      .put<DeliveryPourCard>(createApiUrl(`deliveries-runs/${deliveryRunId}/pour-card`), data)
      .pipe(catchError(this.errorShowAndHandle));
  }

  getLongtermDeliveryTerms(payload: GetLongtermDeliveryTermsPayload): Observable<LongtermDeliveryTerm[]> {
    const { subcontractorId, supplierId, materialCategoryId, startDate, endDate } = payload;

    return this.http.get<LongtermDeliveryTerm[]>(createApiUrl('deliveries/longtime/terms'), {
      params: {
        subcontractorId,
        supplierId,
        materialCategoryId,
        startDate: startDate.toString(),
        endDate: endDate.toString(),
      },
    });
  }

  createDelivery(delivery: CreateDeliveryReq['delivery']): Observable<Delivery> {
    return this.http.post(createApiUrl('deliveries'), delivery).pipe(
      map((response: GetDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  createLongtimeDelivery(delivery: DeliveryLongtime): Observable<DeliveryLongtime> {
    return this.http.post(createApiUrl('deliveries/longtime'), delivery).pipe(catchError(this.errorShowAndHandle));
  }

  updateLongtimeDelivery(delivery: DeliveryLongtime): Observable<DeliveryLongtime> {
    return this.http.put(createApiUrl(`deliveries/longtime/${delivery.id}`), delivery).pipe(
      map((response: Response<{ delivery: DeliveryLongtime }>) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  getDeliveryContacts(deliveryId: string): Observable<DeliveryContacts> {
    const url = `deliveries/${deliveryId}/phones`;
    return this.http.get(createApiUrl(url)).pipe(catchError(this.errorShowAndHandle));
  }

  getDeliveryUnloadingMethods(): Observable<DeliveryUnloadingMethod[]> {
    const url = 'unloading-methods';
    return this.http.get(createApiUrl(url)).pipe(catchError(this.errorShowAndHandle));
  }

  getDeliveryComments(deliveryId: string): Observable<DeliveryComment[]> {
    const url = `deliveries/${deliveryId}/comments`;
    return this.http.get(createApiUrl(url)).pipe(catchError(this.errorShowAndHandle));
  }

  addDeliveryComments(deliveryId: string, comments: DeliveryComment[]): Observable<DeliveryComment[]> {
    const url = `deliveries/${deliveryId}/comments`;
    return this.http.post(createApiUrl(url), comments).pipe(catchError(this.errorShowAndHandle));
  }

  getDistributors(): Observable<Distributor[]> {
    const url = 'distributors';
    return this.http.get(createApiUrl(url)).pipe(catchError(this.errorShowAndHandle));
  }

  updateDeliveryMaterialAmount({
    deliveryId,
    deliveryMaterialId,
    data,
  }: UpdateDeliveryMaterialReq): Observable<DeliveryMaterial[]> {
    return this.http.put<DeliveryMaterial[]>(
      createApiUrl(`deliveries/${deliveryId}/materials/${deliveryMaterialId}`),
      data
    );
  }

  getDeliveryLogs(deliveryId: string): Observable<Log[]> {
    return this.http
      .get<GetDeliveryLogsResponse>(createApiUrl(`deliveries/${deliveryId}/logs`))
      .pipe(map((response) => response.data));
  }

  assignCourseToStage(
    deliveryId: string,
    stageId: string,
    data: { deliveryCourseId: string; deliveredAmount?: number }
  ): Observable<any> {
    return this.http.put(createApiUrl(`deliveries/${deliveryId}/stages/${stageId}`), data);
  }

  // ------------------------------------------------------------------------------------
  // /deliveries/:id

  getDelivery(delivery: { id: string }): Observable<Delivery> {
    return this.http.get(createApiUrl(`deliveries/${delivery.id}`)).pipe(
      map((response: GetDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  updateDelivery(delivery: IUpdateDelivery): Observable<Delivery> {
    const { id, ...payload } = delivery;
    return this.http.put(createApiUrl(`deliveries/${id}`), payload).pipe(
      map((response: UpdateDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  restoreDelivery(delivery: IUpdateDelivery): Observable<Delivery> {
    const { id, ...payload } = delivery;
    return this.http.put(createApiUrl(`deliveries/${id}/restore`), payload).pipe(catchError(this.errorShowAndHandle));
  }

  updateDeliveryUnloading(id: string): Observable<Delivery> {
    const url = `deliveries/${id}/register-unloaded-delivery`;

    return this.http.put(createApiUrl(url), {}).pipe(
      map((response: UpdateDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  updateDeliveryFiles(deliveryFilesReqConfig: UpdateDeliveryFilesReq): Observable<Delivery> {
    const { deliveryId, files, type, action, registrationNumber, deliveryRunId } = deliveryFilesReqConfig;

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file[0] || file);
    });

    if (type && action) {
      formData.append('type', type);
      formData.append('action', action);
    }

    if (deliveryRunId) {
      formData.append('deliveryRunId', deliveryRunId);
    }

    formData.append('registrationNumber', registrationNumber);

    return this.http.put<Delivery>(createApiUrl(`deliveries/${deliveryId}/upload-files`), formData);
  }

  updateAvatar(data: UpdateAvatarFile): Observable<string> {
    const { file, userId } = data;
    const formData = new FormData();

    formData.append('file', file);

    return this.http.put<string>(createApiUrl(`users/${userId}/upload-avatar`), formData);
  }

  urlToBase64(imageUrl: string): Observable<string> {
    return new Observable<string>((observer) => {
      const image = new Image();
      image.src = imageUrl;
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;

        context.drawImage(image, 0, 0);
        const imageObjectURL = canvas.toDataURL('image/png');
        observer.next(imageObjectURL);
        observer.complete();
      };
      image.onerror = (err) => {
        observer.error(err);
      };
    });
  }

  openDeliveryFile(deliveryId: string, fileId: string): Observable<DeliveryFile> {
    return this.http.get<DeliveryFile>(createApiUrl(`deliveries/${deliveryId}/delivery-files/${fileId}`));
  }

  getDeliveryFilesZip(deliveryId: string): Observable<any> {
    return this.http.get(createApiUrl(`deliveries/${deliveryId}/delivery-files-zip`), {
      responseType: 'blob',
    });
  }

  removeDeliveryFile(deliveryId: string, fileId: string): Observable<DeliveryFile[]> {
    return this.http.delete<DeliveryFile[]>(createApiUrl(`deliveries/${deliveryId}/delivery-files/${fileId}`));
  }

  checkInDelivery({
    deliveryId,
    identificator,
    newRegistrationNumber,
    bufferPark,
  }: CheckInDeliveryReq): Observable<Delivery> {
    const url = createApiUrl(`deliveries/${deliveryId}/check-in`);
    const body = {
      identificator,
      newRegistrationNumber,
      bufferPark,
    };

    return this.http.put(url, body).pipe(
      map((response: GetDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  checkOutDelivery({ deliveryId, identificator }: CheckOutDeliveryReq): Observable<Delivery> {
    const url = createApiUrl(`deliveries/${deliveryId}/check-out`);
    return this.http.put(url, { identificator }).pipe(
      map((response: GetDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  rejectDelivery(delivery: Partial<Delivery>): Observable<Delivery> {
    const { id, ...payload } = delivery;
    return this.http.patch(createApiUrl(`deliveries/${id}/reject`), payload).pipe(
      map((response: RejectDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  cancelDelivery(delivery: { id: string }): Observable<Delivery> {
    return this.http.patch(createApiUrl(`deliveries/${delivery.id}/cancel`), {}).pipe(
      map((response: CancelDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  remindDelivery(id: string): Observable<any> {
    return this.http.get(createApiUrl(`deliveries/${id}/send/reminder`)).pipe(
      map((response: any) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  removeDelivery(delivery: { id: string }): Observable<GetDeliveryRes> {
    // fix after back response
    return this.http.delete(createApiUrl(`deliveries/${delivery.id}`)).pipe(
      map((response: GetDeliveryRes) => response), // fix after back response
      catchError(this.errorShowAndHandle)
    );
  }

  callDriver(deliveryId: string): Observable<{}> {
    return this.http.post(createApiUrl(`buffer-parks/recall/${deliveryId}`), {});
  }

  // ------------------------------------------------------------------------------------
  // /deliveries/check

  checkDelivery(delivery: CheckDeliveryReq): Observable<CheckedDeliveries> {
    return this.http.post(createApiUrl('deliveries/check'), delivery).pipe(
      map((response: CheckDeliveryRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /deliveries/propose

  proposeDelivery(delivery: ProposeDeliveryReq): Observable<Propose[]> {
    return this.http.post(createApiUrl('deliveries/propose'), delivery).pipe(
      map((response: ProposeDeliveryRes) => response.data.delivery),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /deliveries/manual/places`

  getManualModeDeliveries(places: GetManualModeDeliveriesReq): Observable<Delivery[]> {
    return this.http.post(createApiUrl('deliveries/manual/places'), places).pipe(
      map((response: GetManualModeDeliveriesRes) => response.data.deliveries),
      catchError(this.errorShowAndHandle)
    );
  }

  sendManualModeDeliveries(data: SendManualModeDeliveriesReq): Observable<Delivery[]> {
    return this.http.post(createApiUrl('deliveries/manual'), data).pipe(
      map((response: SendManualModeDeliveriesRes) => response.data.manualDeliveries),
      catchError(this.errorShowAndHandle)
    );
  }

  setUnloadingAction(id: string, unloadingAction: string): Observable<any> {
    return this.http.put(createApiUrl(`reservations/${id}/action?unloadingAction=${unloadingAction}`), null).pipe(
      map((response: any) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  setManualModeDelivery(id: string) {
    return this.http.put(createApiUrl(`deliveries/manual/${id}/set`), null).pipe(catchError(this.errorShowAndHandle));
  }

  sendDeliveryCards(id: string) {
    return this.http.get(createApiUrl(`deliveries/${id}/delivery-card`)).pipe(catchError(this.errorShowAndHandle));
  }

  downloadDeliveryCard({ id, number }: Delivery): Observable<string> {
    return this.http
      .get(createApiUrl(`deliveries/${id}/delivery-card/download`), {
        responseType: 'blob',
      })
      .pipe(
        tap((pdf) => {
          const timestamp = moment().format('YYYYMMDDhhmmss');

          saveAs(pdf, `DC_D${number}_${timestamp}.pdf`);
        }),

        catchError(this.errorShowAndHandle)
      );
  }

  sendOrders({ deliveryId, data }: SendOrdersReq) {
    return this.http
      .post(createApiUrl(`deliveries/${deliveryId}/send/order`), pickBy(data, identity))
      .pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // /dashboard

  getDashboard(): Observable<Dashboard> {
    return this.http.get(createApiUrl('dashboard')).pipe(
      map((response: GetDashboardRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getDashboardPreview(): Observable<DashboardPreview> {
    return this.http.get(createApiUrl('dashboard/preview')).pipe(
      map((response: { data: DashboardPreview }) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getCanteenDashboard() {
    return this.http.get(createApiUrl('dashboard/canteen')).pipe(
      map((response: { data: DashboardCanteen }) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getTVDashboard() {
    return this.http.get(createApiUrl('dashboard/tv')).pipe(
      map((response: { data: DashboardTV }) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateTVDashboard(data: any) {
    return this.http.post(createApiUrl('dashboard/tv'), data).pipe(
      map((response: { data: DashboardTV }) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  // ------------------------------------------------------------------------------------
  // /materials

  createMaterial(payload: CreateMaterialReq): Observable<Material> {
    return this.http.post(createApiUrl('materials'), payload).pipe(
      map((response: Response<Material>) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  updateMaterial(payload: CreateMaterialReq, id: string): Observable<Material> {
    return this.http.put(createApiUrl(`materials/single/${id}`), payload).pipe(
      map((response: Response<Material>) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getMaterials(params: QueryParamObject[]): Observable<GetMaterialsRes['data']> {
    return this.http.get(createApiUrl(`materials${createQueryParams(params)}`)).pipe(
      map((response: GetMaterialsRes) => response.data),
      catchError(this.errorShowAndHandle)
    );
  }

  getMaterialCategories(params: QueryParamObject[]): Observable<MaterialCategory[]> {
    return this.http.get(createApiUrl(`materials/categories${createQueryParams(params)}`)).pipe(
      map((response: GetMaterialCategoriesRes) => response.data.categories),
      catchError(this.errorShowAndHandle)
    );
  }

  getMaterialCategory(id: string, params: QueryParamObject[]): Observable<MaterialCategory> {
    return this.http.get(createApiUrl(`materials/categories/${id}${createQueryParams(params)}`)).pipe(
      map((response: GetMaterialCategoryRes) => response.data.category),
      catchError(this.errorShowAndHandle)
    );
  }

  getMaterialComments(materialId: string): Observable<MaterialsComment[]> {
    const queryParams = createQueryParams([
      {
        type: QueryParams.Filter,
        filterField: 'materialId',
        condition: 'eq',
        value: materialId,
      },
    ]);
    const url = `deliveries/stages/stage-comments${queryParams}`;
    return this.http.get<MaterialsComment[]>(createApiUrl(url));
  }

  getMaterialDescriptions(materialId: string): Observable<MaterialsDescription[]> {
    const queryParams = createQueryParams([
      {
        type: QueryParams.Filter,
        filterField: 'materialId',
        condition: 'eq',
        value: materialId,
      },
    ]);
    const url = `deliveries/stages/stage-descriptions${queryParams}`;
    return this.http.get<MaterialsDescription[]>(createApiUrl(url));
  }

  getMaterialPackingMethods(): Observable<MaterialPackingMethod[]> {
    return this.http.get<MaterialPackingMethod[]>(createApiUrl('materials/packing-methods'));
  }

  getSuppliers(params: QueryParamObject[]): Observable<MaterialSupplier[]> {
    return this.http.get(createApiUrl(`suppliers${createQueryParams(params)}`)).pipe(
      map((response: GetSuppliersRes) => response.data.suppliers),
      catchError(this.errorShowAndHandle)
    );
  }

  getProducers(params: QueryParamObject[]): Observable<MaterialProducer[]> {
    return this.http.get(createApiUrl(`producers${createQueryParams(params)}`)).pipe(
      map((response: GetProducersRes) => response.data.producers),
      catchError(this.errorShowAndHandle)
    );
  }

  getInspectionRejectReasons(): Observable<RejectReason[]> {
    return this.http.get<RejectReason[]>(createApiUrl('deliveries/inspection/reject-reasons'));
  }

  getInspectionStatuses(): Observable<InspectionStatus[]> {
    return this.http
      .get<GetInspectionStatusesResponse>(createApiUrl('deliveries/inspection/statuses'))
      .pipe(map((response: GetInspectionStatusesResponse) => response.statuses));
  }

  createInspection(
    body: CreateInspectionBody,
    deliveryMaterialId: string,
    deliveryId: string
  ): Observable<CreateInspectionResponse> {
    return this.http.post<CreateInspectionResponse>(
      createApiUrl(`deliveries/${deliveryId}/materials/${deliveryMaterialId}/inspection`),
      body
    );
  }

  getInspectionResults(deliveryId: string, materialId: string): Observable<DeliveryMaterial[]> {
    return this.http.get<DeliveryMaterial[]>(
      createApiUrl(`deliveries/${deliveryId}/materials/${materialId}/inspection/results`)
    );
  }

  getMaterialDestinations(): Observable<MaterialDestination[]> {
    const url = 'materials/destinations';
    return this.http.get(createApiUrl(url)).pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // /settings

  getGeneralSettings(): Observable<GeneralSettings> {
    return this.http.get<GetGeneralSettingsRes>(createApiUrl('settings')).pipe(
      map((response) => response.generalSettings),
      catchError(this.errorShowAndHandle)
    );
  }

  getDeliverySettings(): Observable<DeliverySettings> {
    return this.http.get<GetDeliverySettingsRes>(createApiUrl('settings/delivery')).pipe(
      map((response) => response.deliverySettings),
      catchError(this.errorShowAndHandle)
    );
  }

  getObjectSettings(): Observable<ObjectSettings> {
    return this.http.get<GetObjectSettingsRes>(createApiUrl('settings/objects')).pipe(
      map((response) => response.objectSettings),
      catchError(this.errorShowAndHandle)
    );
  }

  getSubcontractorSettings(): Observable<SubcontractorSettings> {
    return this.http.get<GetSubcontractorSettingsRes>(createApiUrl('settings/subcontractors')).pipe(
      map((response) => response.subcontractorSettings),
      catchError(this.errorShowAndHandle)
    );
  }

  getEcologySettings(): Observable<EcologySettings> {
    return this.http.get<GetEcologySettingsRes>(createApiUrl('settings/ecology')).pipe(
      map((response) => response.ecologySettings),
      catchError(this.errorShowAndHandle)
    );
  }

  getProjectSettings(): Observable<ProjectSettings> {
    return this.http.get<GetProjectSettingsRes>(createApiUrl('settings/project')).pipe(
      map((response) => response.projectSettings),
      catchError(this.errorShowAndHandle)
    );
  }

  getVehicleSizes(): Observable<VehicleSize[]> {
    return this.http.get<{ data: { vehicles: VehicleSize[] } }>(createApiUrl('vehicles/sizes')).pipe(
      map((response) => response.data.vehicles),
      catchError(this.errorShowAndHandle)
    );
  }

  getScheduleColors(): Observable<ScheduleColorsSet[]> {
    return this.http.get<ScheduleColorsSet[]>(createApiUrl('settings-service/schedule-colors'));
  }

  createScheduleColors(colorsSets: ScheduleColorsSet[]): Observable<ScheduleColorsSet[]> {
    return this.http
      .post<ScheduleColorsSet[]>(createApiUrl('settings-service/schedule-colors'), colorsSets)
      .pipe(catchError(this.errorShowAndHandle));
  }

  updateScheduleColors(colorsSets: ScheduleColorsSet[]): Observable<ScheduleColorsSet[]> {
    return this.http
      .put<ScheduleColorsSet[]>(createApiUrl('settings-service/schedule-colors'), colorsSets)
      .pipe(catchError(this.errorShowAndHandle));
  }

  // ------------------------------------------------------------------------------------
  // /report

  sendReport(reportData: SendReportReq): Observable<GetReportsRes> {
    return this.http.post(createApiUrl('reports/send'), reportData).pipe(
      map((response: GetReportsRes) => response),
      catchError(this.errorShowAndHandle)
    );
  }

  getReport(report: {
    reportQueries?: ReportQueries;
    queries: QueryParamObject[];
    customUrl?: string;
  }): Observable<Report> {
    const queryParams = createQueryParams(report.queries);
    const reportTypeQuery = report.reportQueries ? `&reportType=${report.reportQueries.reportType}` : '';
    const url = `reports/type/range${queryParams}${reportTypeQuery}`;

    return this.http.get<GetReportRes>(report.customUrl || createApiUrl(url)).pipe(
      map((response) => response.data.report),
      catchError(this.errorShowAndHandle)
    );
  }

  getUnits(): Observable<Unit[]> {
    return this.http.get<Unit[]>(createApiUrl('units'));
  }

  // ------------------------------------------------------------------------------------
  // /integrations

  getIntegrationToken(client: string): Observable<string> {
    return this.http.get<{ data: { token: string } }>(createApiUrl(`integrations/token?client=${client}`)).pipe(
      map((response) => response.data.token),
      catchError(this.errorShowAndHandle)
    );
  }

  getIntegrationSettings(): Observable<any> {
    return this.http.get<{ integrationSettings: {} }>(createApiUrl('settings/integrations')).pipe(
      map((response) => response.integrationSettings),
      catchError(this.errorShowAndHandle)
    );
  }
}
