import { Injectable } from '@angular/core';

import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

let uniqueId = 0;
const getUniqueId = () => ++uniqueId;

export type AlertType = 'info' | 'error';

interface AlertConfig {
  type: AlertType;
  message: string;
  startAnimation?: boolean;
  offset?: number;
  time?: number;
  hideHeader?: boolean;
  ignoreAlertDuration?: boolean;
  closeAllErrors?: boolean;
}

export interface Alert extends AlertConfig {
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private defaultOffset = 50;
  private defaultTime = 5000;
  private alertSource = new Subject<Alert | null>();
  private alertPromises: {
    [id: number]: Promise<Alert>;
  } = {};

  private closedAlertSource = new Subject<Alert>();

  alert$ = this.alertSource.asObservable();
  closedAlert$ = this.closedAlertSource.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.clear();
      }
    });
  }

  clear() {
    this.alertSource.next(null);
  }

  onCloseAlert(alert: Alert) {
    this.closedAlertSource.next(alert);
  }

  private alert(config: AlertConfig) {
    const { type, message, offset, time, ignoreAlertDuration, hideHeader, closeAllErrors } = config;
    const alert: Alert = {
      id: getUniqueId(),
      type,
      message,
      offset,
      time,
      ignoreAlertDuration,
      hideHeader,
    };

    if (closeAllErrors) {
      this.clear();
    }

    this.alertSource.next(alert);

    return this.closedAlert$.pipe(
      filter((closed) => closed.id === alert.id),
      take(1)
    );
  }

  public showInfo(
    message: string,
    { offset = this.defaultOffset, time = this.defaultTime, ignoreAlertDuration = false } = {}
  ) {
    const alertInfoConfig: AlertConfig = {
      type: 'info',
      message,
      offset,
      time,
      ignoreAlertDuration,
    };
    return this.alert(alertInfoConfig);
  }

  public showError(
    message: string,
    {
      offset = this.defaultOffset,
      time = this.defaultTime,
      ignoreAlertDuration = false,
      hideHeader = false,
      closeAllErrors = false,
    } = {}
  ) {
    const alertErrorConfig: AlertConfig = {
      type: 'error',
      message,
      offset,
      time,
      ignoreAlertDuration,
      hideHeader,
      closeAllErrors,
    };
    return this.alert(alertErrorConfig);
  }
}
