import { NavigationExtras, Params } from '@angular/router';
import { DeliveryFrontStatus, Theme } from '@app/config/interfaces';
import { EquipmentTypes, MapObjectTypes } from '@app/core/http/interfaces';
import { DeliveryDirections } from '@app/shared/@constants/delivery-directions';
import { DeliveryRunReceptionStatuses } from '@app/shared/@constants/delivery-run-reception-statuses';
import { DeliverySetTimeActions } from '@app/shared/@constants/delivery-set-time-actions';
import { ReservationReasons } from '@app/shared/@constants/reservation-reasons';
import { AddDeliveryMaterialForm } from '@app/shared/deliveries/add-delivery/add-delivery-material/add-delivery-material.component';
import { NewDeliveryOther } from '@app/shared/deliveries/add-delivery/add-delivery.utils';
import { DELIVERY_STAGE_STATUS, POSSIBLE_STAGE_ACTIONS, Supplier, SupplierLocation } from '@interfaces';
import { DELIVERY_RUN_STATUSES } from '@app/shared/@constants/delivery-run-statuses';
import { config } from '@app/app.config';

import {
  DeliveryTypeDetail,
  DeliveryStatus,
  PermissionsEnum,
  UserRoles,
  Role,
  DeliveryType,
} from '@ppgt/web/shared/domain';

export enum KeyCodes {
  ESCAPE = 27,
  ENTER = 13,
}

export interface BasicModel {
  createdAt?: string;
  updatedAt?: string;
}

export interface ReducerMap<T> {
  [key: string]: (state: T, payload?: any) => T;
}

export interface Action {
  type: string;
  payload?: any;
}

export enum Permission {
  ADMINISTRATION,
  USER,
}

export interface IntegrationLinkData {
  integration: boolean;
  client: string;
  integrationUrl: string;
}

export interface MenuTab {
  title: string;
  path?: string;
  withPermission?: PermissionsEnum;
  dropdown?: MenuTab[];
  icon?: string;
  mobileTitle?: string;
  monitoringCo2Required?: boolean;
  params?: { roleType: UserRoles };
  testId?: string;
  ignoreFill?: boolean;
  data?: any;
  desktopOnly?: boolean;
}

export interface User extends BasicModel {
  id?: string;
  activationDate?: string;
  email: string;
  theme: Theme;
  lastPasswordChange: Date;
  role: Role;
  fullName?: string;
  phone?: string;
  active?: boolean;
  roleId?: string;
  subcontractor?: Subcontractor;
  supplier?: Supplier;
  gates?: Gate[];
  ownEquipment?: Equipment;
  ownGate?: Gate;
  avatar?: string; // URL to avatar file
  thumbnail?: string; // URL to avatar thumbnail file
  language?: string;
  allowSMSNotifications: boolean;
  allowEmailNotifications: boolean;
  dateRegulations: string;
  isPasswordActive: boolean;
  lastActivity: string;
  passwordHash: string;
  verified: boolean;
  isSuperUser: boolean;
  createdById: string;
  pin?: number;
}

export interface Notif extends BasicModel {
  id?: string;
  seen: boolean;
  type: string;
  message?: string;
  active?: boolean;
  ignoreAlertDuration?: boolean;
}

export interface Error {
  error: string;
}

export interface HoursWork {
  hour: number;
  deliveries: number;
}

export enum LongtimeDeliveryCategories {
  Containers = 'containers',
  Gravel = 'gravel',
  Toilets = 'toilets',
  Waste = 'waste',
  DredgingSpoil = 'dredgingSpoil',
  DredgingSpoils = 'dredgingSpoils',
  Fuel = 'fuel',
  Concrete = 'concrete',
  SteelElements = 'steelElements',
}

export enum MobileMenuSections {
  QuantityUpdate = 'update',
  QRCode = 'qr-code-menu',
  Contacts = 'contacts',
  MaterialInspection = 'inspection',
  ActivityLog = 'activity-log',
}

export enum DeliveryOptions {
  gateEnter = 'gate-enter',
  gateLeave = 'gate-exit',
  direction = 'direction',
  crane = 'crane',
  elevator = 'elevator',
  excavator = 'excavator',
  miniExcavator = 'mini-excavator',
  forklift = 'forklift',
  mobileCrane = 'mobile-crane',
  place = 'place',
  depot = 'storage',
}

export type InspectionStatusesKeys = keyof typeof config.inspectionStatuses;

export interface Building extends BasicModel {
  id: string;
  number: number;
  shortName: string;
  minFloor: number;
  maxFloor: number;
  status: boolean;
  gateBuildings?: GateBuilding[];
  depots: Depot[];
  equipment: Equipment[];
  gates: Gate[];
  places: UnloadingPlace[];
}

interface GateBuilding extends BasicModel {
  buildingId: string;
  gateId: string;
}

interface DepotTime extends BasicModel {
  id: string;
  from: string;
  to: string;
  depotId: string;
}

export interface Depot extends BasicModel {
  id: string;
  number: number;
  shortName: string;
  status: boolean;
  width: number;
  length: number;
  userId: string;
  subcontractorId: string;
  user: User;
  subcontractor: Subcontractor;
  places: UnloadingPlace[];
  actualFree: number;
  buildings: Building[];
  depotTimes: DepotTime[];
  archived: boolean;
  area: number;
  hoursWork?: HoursWork[];
  buildingSectionId?: string;
  buildingFloor: {
    floorNumber: number;
  };
}

export interface MaterialDepot extends BasicModel {
  id: string;
  shortName: string;
  number: number;
}

interface ElevatorBuilding extends BasicModel {
  buildingId: string;
  elevatorId: string;
}

export interface EquipmentTimes extends BasicModel {
  equipmentId: string;
  from: string;
  id: string;
  to: string;
  dayNumber?: number;
}

interface ElevatorTimes extends BasicModel {
  elevatorId: string;
  from: string;
  id: string;
  to: string;
}

export interface Elevator extends BasicModel {
  id: string;
  number: number;
  shortName: string;
  maxCapacity: number;
  location: string;
  width: string;
  length: string;
  widthEnter: string;
  lengthEnter: string;
  minFloor: number;
  maxFloor: number;
  active: boolean;
  status: boolean;
  user: User;
  places: UnloadingPlace[];
  hoursWork?: HoursWork[];
  archived: boolean;
  heightEnter: number;
  operatorId: string;
  userId: string;
  operator: null;
  buildings: {
    elevatorBuildings: ElevatorBuilding[];
    id: string;
    maxFloor: number;
    minFloor: number;
    number: number;
    shortName: string;
    status: boolean;
  }[];
  elevatorTimes: ElevatorTimes[];
}

export interface Equipment extends BasicModel {
  id: string;
  number: number;
  code: string;
  shortName: string;
  maxCapacity: number;
  brand: string;
  model: string;
  identifier: string;
  floorFrom: number;
  floorTo: number;
  status: boolean;
  active: boolean;
  type: EquipmentTypes;
  typeObject?: { typeName: string | EquipmentTypes };
  userId: string;
  user: User;
  places: UnloadingPlace[];
  hoursWork?: HoursWork[];
  buildings?: Building[];
  lift: number;
  fork: number;
  diggingDepth: number;
  width: number;
  weight: number;
  archived: boolean;
  subcontractorId: string;
  operatorId: string;
  subcontractor: Subcontractor;
  operator: { id: string; phone: string; fullName: string };
  equipmentTimes: EquipmentTimes[];
  equipmentBrandId?: number;
  equipmentModelId?: number;
  settings?: { type: string };
}

export interface EquipmentType extends BasicModel {
  id: number;
  typeName: string;
  translation?: Translation;
  translationId?: string;
  defaultEquipment: boolean;
}

export interface EquipmentDeliveriesDetails extends BasicModel {
  type: EquipmentTypes | MapObjectTypes;
  shortName: string;
  id: string;
  busyTerms: EquipmentDeliveriesDetailsBusyTerm[];
  freeTerms: EquipmentDeliveriesDetailsFreeTerm[];
  combinedTerms?: (EquipmentDeliveriesDetailsFreeTerm | EquipmentDeliveriesDetailsBusyTerm)[];
  buildings?: Building[];
  calculate: {
    dayshift: number;
    nightshift: number;
  };
}

export interface EquipmentDeliveriesDetailsBusyTerm extends EquipmentDeliveriesDetailsFreeTerm {
  delivery: {
    id: string;
    deliveryNumber: string;
    subcontractor: {
      companyCode: string;
      scheduleColor: {
        textColor: string;
      };
    };
  };
  slotType: string;
}

export interface EquipmentDeliveriesDetailsFreeTerm {
  expectedFrom: string;
  expectedTo: string;
  inCurrentTime: boolean;
  slotType: string;
  delivery: any;
}

export interface Gate extends BasicModel {
  id: string;
  number: number;
  shortName: string;
  enter: boolean;
  leave: boolean;
  status: boolean;
  active: boolean;
  width: number;
  street: string;
  accessControl: string;
  userId: string;
  operatorId: string;
  user?: User;
  buildings?: Building[];
  placeDeliveryTimes?: PlaceDeliveryTime[];
  places?: UnloadingPlace[];
  operator: string;
  hoursWork?: HoursWork[];
  placeGate: PlaceGate;
  archived: boolean;
  gateTimes: GateTime[];
  defaultGateIn: boolean;
  defaultGateOut: boolean;
}

export interface GateTime extends BasicModel {
  from: string;
  gateId: string;
  id: string;
  to: string;
}

export interface PlaceGate extends BasicModel {
  enter: boolean;
  leave: boolean;
  gateId: string;
  placeId: string;
}

export interface UnloadingPlace extends BasicModel {
  id: string;
  number: number;
  shortName: string;
  destination: string;
  active: boolean;
  excavator: boolean;
  forklift: boolean;
  miniExcavator: boolean;
  mobileCrane: boolean;
  status: boolean;
  height: number;
  width: number;
  depth: number;
  equipment: Equipment[];
  gates: Gate[];
  placeDeliveryTimes?: PlaceDeliveryTime[];
  hoursWork?: HoursWork[];
  buildings?: Building[];
  hotspot: boolean;
  maxUnloadingTimeInWork?: number;
  maxUnloadingTimeNotWork?: number;
  depotId?: string;
  userId?: string;
  elevators?: Elevator[];
  depot?: Depot;
  user?: User;
  vehicleSizes?: VehicleSize[];
  details?: string;
  archived?: boolean;
  enterGates?: Gate[];
  leaveGates?: Gate[];
  occupancy: number;
}

export interface Contract extends BasicModel {
  id: string;
  startTime: string;
  endTime: string;
  number: number;
  active: boolean;
  longContract: boolean;
  cargoType: string;
  amountCargo: number;
  unit: string;
  limitVehicles: number;
  subcontractorId: string;
  subcontractor: Subcontractor;
  materialId: string;
  courses: number;
  deliveredAmount: number;
  deliveredCargo: number;
  materialCategory: MaterialCategory;
  materialCategoryId: string;
  contractNumber?: string;
  supplier?: MaterialSupplier;
  supplierId?: string;
  sendSmsToSubRequestBeforeConfirmationDelivery: number;
  deadlineRequestUpdateBeforeDeliverySent: number;
  deadlineRequestUpdateBeforeDeliverySentWithPump: number;
  avgArrivalTimePlantToSite: number;
  deadlineTimesContracts: DeadlineTimeContract[];
  dischargeTimeRequirements: number;
}

export interface ConstructionCrew extends BasicModel {
  id: string;
  leaderId: string;
  leader: User;
  name: string;
  preferredEquipmentId: string;
  preferredEquipment: Partial<Equipment>;
  subcontractorId?: string;
}

export interface DeadlineTimeContract {
  dayNumber: number;
  deadlineDate: string;
  limitOnSpecificDay: number;
  translationKey: string;
  isActive: boolean;
}

export interface Subcontractor extends BasicModel {
  id?: string;
  companyName: string;
  companyCode?: string;
  web: string;
  status: boolean;
  longDeliveries: boolean;
  shortDeliveries: boolean;
  subcontractorUser: SubcontractorUser;
  subcontractorLocations: SupplierLocation[];
  materialCategories: MaterialCategory[];
  courses?: number;
  contracts?: Contract[];
  materials?: Material[];
  scheduleColorId?: number;
}

export interface SubcontractorAsClient extends Subcontractor {
  toggled?: boolean;
}

export interface SubcontractorUser extends BasicModel {
  defaultEmployee: boolean;
  id: string;
  subcontractorEmployeeRoleId: string;
  subcontractorId: string;
  userId: string;
  user: User;
}

export interface Vehicle extends BasicModel {
  id: string;
  generalType: string;
  detailTypeVehicle: string;
  co2: string;
  engine: string;
  translation: string;
  materialCategoryId?: string;
  subcontractorId?: string;
  registrationNumber?: string;
}

export interface VehicleObj extends BasicModel {
  id: string;
  /**
   * Is vehicle assigned to longtermm deliveries
   */
  longTime: boolean;

  registrationNumber: string;

  /**
   * aka Side Number
   */
  vehicleIdentifier: string;
  cargoVolume: number;
  unit: string;
  inflammable: boolean;
  status: boolean;
  /**
   * Vehicle might belong to subcontractor but also to supplier
   */
  subcontractorId?: string;
  subcontractor?: Subcontractor;

  /**
   * Vehicle might belong to subcontractor but also to supplier
   */
  supplierId?: string;
  supplier: Supplier;

  vehicleId: string;
  vehicle: Vehicle;
  courses: number;
  materialCategory: MaterialCategory;
  materialCategoryId: string;
  translatedName?: string;
  displayName?: string;
}

export interface SubcontractorLocation extends BasicModel {
  id?: string;
  address?: string;
  distance?: number;
  subcontractorId?: string;
  averageTravelTime?: any;
  name?: string;
  zipCodeWithCity?: string;
  street?: string;
  defaultLocation?: boolean;
}

export interface SubMaterial extends BasicModel {
  id: string;
  name: string;
  translations: { pl: string; en: string } | null;
  materialId: string;
}

export interface MaterialDelivery extends BasicModel {
  id: string;
  amount: string;
  unit: string;
  deliveryId: string;
  materialId: string;
}

export interface Translation extends BasicModel {
  id: string;
  data: { [key: string]: string };
}

export interface RejectReason extends BasicModel {
  id: string | null;
  name: string | null;
  type?: string;
  translation?: Translation;
}

export interface InspectionStatus {
  statusKey: string;
  positive: boolean;
  translationKey: string;
  requiredComment: boolean;
}

export interface CreateInspectionBody {
  inspectionRequestIdentifier: string;
  inspectionStatus: string;
  rejectReasons?: RejectReason[];
  decisionComment?: string;
}

export interface GetDeliveryLogsResponse {
  data: Log[];
}

export interface GetDeliveryLogsSuccessPayload {
  logs: Log[];
  deliveryId: string;
}

export type MaterialDurability = 'permanent' | 'consumables' | 'temporary';

export interface Material extends BasicModel {
  id: string;
  name: string;
  main: boolean;
  createdById: string;
  translation: Translation | null;
  producer: MaterialProducer;
  suppliers: MaterialSupplier[];
  marIdentifier: string;
  materialCategoryId: string;
  translationId: string;
  materialCategory: MaterialCategory;
  translatedName?: string;
  label?: string;
  description: string;
  producerId: string;
  subcontractorId?: string;
  subcontractors?: Subcontractor[];
  emergency: boolean;
  grade: any;
  mixCode: any;
  slump: any;
  flow: any;
  maxAmount: number;
  deliveredMaterial: number;
  unit: string;
  units: string[];
  durability: MaterialDurability;
  internalIdentifier?: string;
  status: string;
  productCode?: string;
  assemblyDate?: Date | string;
  suppliersManufacturedItemId?: string;
  isPrecast: boolean;
  deliveryMaterials?: DeliveryMaterial[];
  buildingId?: string;
}

export interface GroupedMaterialAsProduct {
  id?: string;
  name: string;
  toggledClients?: boolean;
  toggledDetails?: boolean;
  deliveredMaterials?: number;
  deliveryMaterials?: DeliveryMaterial[];
  subcontractors?: Subcontractor[];
  amountDelivered?: number;
  maxAmount?: number;
  materialCategoryId?: string;
  producerId?: string;
  buildingId?: string;
  groupedMaterials: Material[];
  amountsGroupByUnit?: GroupedMaterialUnits;
  marIdentifier?: string;
}

export interface GroupedMaterialUnits {
  [key: string]: { maxAmount?: number; scheduled?: number; delivered?: number };
}

export interface MaterialProducer extends BasicModel {
  id: string;
  name: string;
  producerMaterial?: ProducerMaterial;
}

interface ProducerMaterial extends BasicModel {
  id: string;
  materialId: string;
  producerId: string;
}

export interface SubcontractorMaterial extends BasicModel {
  id: string;
  name: string;
}

export interface MaterialSupplier extends BasicModel {
  id: string;
  name: string;
  supplierMaterial?: SupplierMaterial;
  materialCategoryId?: string;
}

interface SupplierMaterial extends BasicModel {
  id: string;
  materialId: string;
  supplierId: string;
}

export interface MaterialPackingMethod extends BasicModel {
  id: number;
  materialCategoryId: string;
  name: string;
  translation?: Translation;
}

export interface MaterialCategoryTags extends BasicModel {
  data: { [langKey: string]: string };
  id: string;
}

export interface MaterialCategory extends BasicModel {
  id: string;
  name: string;
  longTimeName?: string;
  inflammable?: boolean;
  definedUnit?: string;
  nameForDashboard?: string;
  defaultCapacity?: number;
  defaultCarType?: any;
  defaultDestination?: 'in' | 'out' | 'both';
  createdById?: string;
  translationId?: string;
  materials?: Material[];
  translation?: Translation | null;
  translatedName?: string;
  tags?: MaterialCategoryTags;
  tagsList?: string;
  pbc?: string;
  tagsId?: string;
  label?: string;
  materialDestinations?: MaterialDestination[];
  unloadingMethods?: DeliveryUnloadingMethod[];
}

export interface MaterialDestination extends BasicModel {
  id?: string;
  name: string;
  translationId?: string;
  translation?: Translation;
}

export interface UnloadingUtility extends BasicModel {
  id: number;
  sectionName: string;
  sectionTranslationId: string;
  sectionTranslation: Translation;
  utilityName: string;
  utilityNameTranslationId: string;
  utilityNameTranslation: Translation;
  priorityInForm: number;
  weightMin: number;
  weightMax: number;
  materialCategory: MaterialCategory;
  materialPackingMethodId: string;
  translatedUtilityName?: string;
  formControlName?: string;
}

export interface EquipmentBrand extends BasicModel {
  id: number;
  name: string;
}

export interface EquipmentModel extends BasicModel {
  equipmentBrandId: number;
  id: number;
  name: string;
}

export interface EquipmentReservation extends BasicModel {
  id?: string;
  equipmentId: string;
  equipment?: Equipment;
  equipmentType?: EquipmentType;
  reservationReason: ReservationReasons;
  subcontractorId: string;
  subcontractor?: Subcontractor;
  equipmentTypeId: number;
  expectedFrom: string | Date;
  expectedTo: string | Date;
  responsiblePersonId: string;
  materialCategoryId: string;
  unitId: string;
  packageInfo: {
    width: number;
    height: number;
    depth: number;
    weight: number;
  };
  totalWeight: number;
  locationSourceId: string;
  locationSourceZone: string;
  locationDestinationId: string;
  locationDestinationZone: string;
  comment: string;
  unloadingUtilities: any[];
  materialPackingMethodId: string;
  objectId?: string;
  amount?: number;
  type?: string;
}

export interface ScheduleColorsSet {
  id?: number;
  borderColor: string;
  textColor: string;
  backgroundColor: string;
  name: string;
}

export interface DeliveryLoad {
  category?: string;
  id?: string;
  name?: string;
  description?: string;
  expectedAmount: number;
  unit: string;
  inflammable?: boolean;
  producer?: string;
  supplier?: string;
  material?: string;
  marIdentifier?: string;
  remarks: string;
  subcontractorId?: string;
}

export interface Delivery extends BasicModel {
  id: string;
  typeDelivery: DeliveryType;
  code: string;
  unloadingTime: number;
  excavator: { shortName: string; id: string };
  forklift: { shortName: string; id: string };
  miniExcavator: { shortName: string; id: string };
  status: DeliveryStatus | string;
  combined: boolean;
  dateUnloading: string;
  specialTime: boolean;
  comment: string;
  dateComment: Date;
  phoneDriver: string;
  reasonReject: string;
  dateRejection: Date;
  entryDate: Date | string;
  isOnSite: boolean;
  import: boolean;
  export: boolean;
  entry24: boolean;
  subcontractorId: string;
  miniExcavatorId?: string;
  mobileCraneId: string;
  placeId: string;
  elevatorId: string;
  depotId: string;
  craneId?: string;
  authorCommentId: string;
  personRejectingId: string;
  equipmentId: string;
  buildingId: string;
  gateEnterId?: string;
  gateLeaveId?: string;
  placeFrom: SupplierLocation;
  placeFromId?: string;
  distanceFromPlace?: number;
  vehicleSubcontractorId?: string;
  registrationNumber?: string;
  inflammable?: boolean;
  departureDate?: Date | string;
  identificator?: string;
  part?: string;
  activityDays?: string[];
  days?: any[];
  number: number;
  type?: string;
  emergency: boolean;
  place: UnloadingPlace;
  logs: Log[];
  codeLongTime: string;
  co2: number;
  courses: number;
  addressStorage: string;
  manual: boolean;
  passengers: number;
  destination: any;
  depotAmount: number;
  depotDays: number;
  typeDetail: DeliveryTypeDetail;
  direction: string;
  vehicleId: string;
  excavatorId: string;
  forkliftId: string;
  deliveryId: string;
  vehicleSizeId: string;
  destinationEquipmentId: string;
  contractId: string;
  createdById: string;
  subcontractor: Subcontractor;
  building: Building;
  vehicleSubcontractor: Vehicle;
  vehicleType?: string;
  gateEnter: Gate;
  gateLeave: Gate;
  depot: Depot;
  elevator: Elevator;
  elevatorFloor?: number;
  crane: Equipment;
  vehicle: Vehicle;
  destinationEquipment: Equipment;
  vehicleSize: string;
  steps?: DeliveryStep[];
  detailStatus: DeliveryFrontStatus;
  deliveryMaterials: DeliveryMaterial[];
  contract: Contract;
  deliveryStages?: DeliveryStage[];
  deliveryComments: string[];
  toggled?: boolean;
  deliveryEntrances: DeliveryEntrance[];
  detailedDescription: string;
  expectedHours: string;
  distributorId: string;
  distributor?: Distributor;
  deliveryInspectionStatus: InspectionStatusesKeys;
  deliveryMaterialAmount?: {
    expected: number;
    actual: number;
  };
  runs?: {
    expected: number;
    actual: number;
  };
  vehicles?: {
    expected: number;
    actual: number;
  };
  unloadingMethods?: DeliveryUnloadingMethod[];
  unloadingStart?: string;
  unloadingFinish?: string;
  unloadingIntervals?: { start: string; stop: string }[];
  ticketIdentifier: string;
  stagesCount: number;
  expectedAmountCourses: number;
  doneAmountCourses: number;
  expectedAmountMaterial: number;
  expectedDateRange?: Date[];
  realDateRange?: Date[];
  expectedUnloadingTime: number;
  firstStageDate?: string;
  lastStageDate?: string;
  deliveryRunsLength?: number;
  materialName?: string;
  projectName?: string;
  projectCode?: string;
  delivery?: Delivery;
  deliveryFiles?: DeliveryFile[];
  bufferPark?: string;
  bufferParkingEntryDate?: string;
  repeatedFromDeliveryId?: string;
  runNumber?: string;
  truckMixer?: VehicleObj;
  floor?: number;
  materials?: Material[];
  deliveredAmountMaterial?: number;
}

export interface DeliveryFile extends BasicModel {
  id: string;
  originalName: string;
  storageName: string;
  tempPubUrl: string;
  materialId: string;
  subcontractorId: string;
  type: string;
  deliveryId: string;
  deliveryRunId?: string;
}

export interface SendOnetimeDeliveryTicketImagesBody {
  type: string;
  files: File[];
  subcontractorId: string;
  materialId: string;
}

export interface DeliveryLongterm extends Delivery {
  id: string;
  dateUnloading: string;
  number: number;
  subcontractor: Subcontractor;
  deliveryMaterials: DeliveryMaterial[];
  deliveryStages?: DeliveryStage[];
  expectedAmountMaterial: number;
  deliveredAmountMaterial?: number;
  expectedDeliveryRuns: number;
  arrivedDeliveryRuns?: number;
  expectedDeliveryRunsUnloadingTime: number;
  stagesCount: number;
  unassignedDeliveryRuns: any; // TODO
  travelTime?: any;
}

export interface DeliveryStage extends BasicModel {
  amount?: number;
  amountSize: number;
  amountStage: number;
  buildingFloorFrom: number;
  buildingFloorTo: number;
  code?: string;
  contaminated: boolean;
  date: string | number;
  deliveryId: string;
  depotArea: number;
  depotId: string;
  from: string;
  id?: string;
  material: Material;
  materialDestinationId: string;
  materialDestination: { name: string };
  materialId: string;
  maxLength: number;
  palletized: boolean;
  stageCommentId: string;
  stageDescriptionId: string;
  to: string;
  unit: string;
  unloadingMethodId: string;
  deliveries: any[];
  toggled?: boolean;
  deliveryEntrances: DeliveryEntrance[];
  expectedCourses: number;
  expectedUnloadingTime: number;
  label: string;
  unloadingMethod: DeliveryUnloadingMethod;
  toRemove?: boolean;
  stageComment?: {
    id?: string;
    comment?: string;
  };
  stageDescription?: {
    id?: string;
    comment?: string;
  };
  flow?: string;
  slump?: string;
  isRequestMobilePump?: boolean;
  craneId?: string;
  crane?: Equipment;
  pipelineQuantity?: number;
  boomLength?: number;
  runFrequency?: number;
  ratePerHour?: number;
  remarks?: string;
  isCubesRequired?: boolean;
  callOfNumber?: string;
  status?: DELIVERY_STAGE_STATUS;
  deliveryRuns?: DeliveryRun[];
  currentAmountSize?: number;
  responsibleEmployee?: User;
  destinationComment?: string;
  wasMoved?: boolean;
}

export interface DeliveryRun extends BasicModel {
  id: string;
  deliveryTicketIdentifier: string;
  deliveryRunNumber?: string;
  delivery?: Delivery;
  quantity: string;
  isConcrete: boolean;
  pumpRequired: boolean;
  runNumber: number;
  status: DELIVERY_RUN_STATUSES;
  batchStart: Date;
  arrivedOnSite: Date;
  onTheWayTime?: Date;
  awaitingOutsideTime?: Date;
  departedFromSite: Date;
  unloadingStart: Date;
  deadlineToUseTill: Date;
  unloadingFinish: Date;
  returnedOnSourceTime?: Date;
  deliveryId: string;
  deliveryStageId: string;
  truckDriverId: string;
  truckMixerId: string;
  truckMixer?: VehicleObj;
  truckDriver?: User;
  deliveryStage?: DeliveryStage;
  deliveryNumber?: number;
  materialName?: string;
  times?: {
    expireMaterialTime?: Date;
    receiptTime?: Date;
  };
  projectName?: string;
  materialDetails?: {
    pouredQuantity?: number;
    sendedQuantity?: number;
    orderedQuantity?: number;
  };
  receptionStatus?: DeliveryRunReceptionStatuses;
  project?: {
    projectName: string;
    constructionSiteAddress: string;
  };
  location?: {
    distance: number;
  };
  ticketUrl?: string;
  identificatorOnGate?: string;
  orderName?: string;
  bufferPark?: string;
  isOnSite?: boolean;
  bufferParkingEntryDate?: string;
  bufferParkingDepartureDate?: string;
  deliveryNoteId?: number;
  deliveryNote?: {
    id: number;
    originalName: string;
    storageName: string;
    type: string;
  };
  generateDeliveryNote?: boolean;
  deliveryFiles?: DeliveryFile[];
  deliveryRunPourCards: DeliveryPourCard[];
}

export interface DeliveryPourCard {
  pourCardIdentifier: string;
  quantity: {
    deliveryNote: number;
    poured: number;
    approved: boolean;
  };
  temperature: {
    deliveryNote: number;
    onSiteTest: number;
    air: number;
    approved: boolean;
  };
  slump: {
    deliveryNote: number;
    onSiteTest: number;
    approved: boolean;
  };
  flow: {
    deliveryNote: number;
    onSiteTest: number;
    approved: boolean;
  };
  deliveryRunCubeTests: CubeTestsData[];
}

export interface GetLongtermDeliveryTermsPayload {
  startDate: number;
  endDate: number;
  subcontractorId: string;
  supplierId: string;
  materialCategoryId: string;
}

export interface LongtermDeliveryTerm {
  date: string;
  deliveryId: string;
  deliveryNumber: number;
  stages: {
    id: string;
    date: string;
    code: string;
  }[];
}

export interface CubeTestsData {
  id?: string;
  testingPurposes: CubeTestsPurpose[];
  rangeFrom: number;
  rangeTo: number;
}

export interface CubeTestsPurpose extends BasicModel {
  id: number;
  name: string;
  translation: Translation;
}

export interface DeliveryEntrance {
  id: string;
  deliveryCourse: {
    dateUnloading: string;
    departureDate: string;
    entryDate: string;
    id: string;
    registrationNumber: string;
    status: string;
  };
  deliveryCourseId?: string;
  deliveryId?: string;
  deliveryStageId?: string;
}

export interface RunPanelConfig {
  runId?: string;
  opened?: boolean;
}

export interface DeliveryLongtimeMaterial {
  materialCategoryId: string;
  producer: { id: string | null; name: string | null };
  supplier: { id: string | null; name: string | null };
}

export interface DeliveryLongtime extends BasicModel {
  id?: string;
  dateUnloading?: number;
  contractId?: string;
  subcontractorId?: string;
  material?: DeliveryLongtimeMaterial;
  buildingId?: string;
  import?: boolean;
  export?: boolean;
  placeFromId?: string;
  gateEnterId?: string;
  gateLeaveId?: string;
  distributor?: Distributor;
  deliveryStages?: DeliveryStage[];
  detailedDescription?: string;
  deliveryMaterials?: DeliveryMaterial[];
  typeDelivery?: DeliveryType.Longtime;
  detailStatus?: DeliveryFrontStatus;
  materialCategoryId?: string;
}

export interface DeliveryViewConfig {
  menuSectionsKeys?: string[];
  deliveryMaterials: DeliveryMaterial[];
  currentMaterial: DeliveryMaterial;
  currentMaterialIndex?: number;
  deliveryOptions: DeliveryOption[];
  deliveryOptionsChunk: DeliveryOption[];
  building: Params;
  isBidirectional: boolean;
  isExportOnly: boolean;
  isID?: boolean;
  activityLogMode?: boolean;
  switchNumber?: number;
}

export enum DeliveryOptionsIcons {
  MaxIconsLimit = 5,
  InitialSwitchNumber = 0,
}

export interface DeliveryOption {
  name: string;
  shortName: string;
  direction?: string;
}

export interface ContactPerson {
  fullName: string;
  phone: string;
  role: string;
}

export interface DeliveryContacts {
  prefix: string;
  phones: ContactPerson[];
}

export interface DeliveryUnloadingMethod extends BasicModel {
  id: string;
  name: string;
  defaultUnloadingTime: number;
  actualTime: number;
  expectedTime: number;
  translation: Translation;
  iconName: string;
  slug?: string;
  length?: number;
}

export interface DeliveryComment extends BasicModel {
  id?: string;
  comment: string;
  deliveryId: string;
  userId: string;
}

export interface Distributor extends BasicModel {
  id?: string;
  name?: string;
  phone?: string;
}

export interface DeliveryMaterial extends BasicModel {
  id: string;
  unit: string;
  combined: boolean;
  direction: DeliveryDirections;
  deliveryId: string;
  delivery?: Delivery;
  materialId: string;
  producerId: string;
  supplierId: string;
  materialCategoryId: string;
  material: Material;
  expectedAmount: number;
  deliveredAmount: number;
  currentAmount: number;
  materialStatus: string;
  materialCategory: MaterialCategory;
  producer: MaterialProducer;
  supplier: MaterialSupplier;
  palletized: boolean;
  deliveryMaterialInspections?: MaterialInspection[];
  marIdentifier: string;
  description: string;
  materialNumber: number;
  weight: number;
  quantityAtTicket?: number;
  calculatedQuantity?: number;
  tempAtTest?: number;
  tempAtTicket?: number;
  slumpAtTest?: number;
  slumpAtTicket?: number;
  flowAtTest?: number;
  flowAtTicket?: number;
  ticketId: number;
  remarks: string;
  inflammable?: boolean;
  import?: boolean;
  export?: boolean;
  units?: string[];
  slump?: string;
  flow?: string;
}

export interface MaterialInspection extends BasicModel {
  id: string;
  inspectionDate: string;
  readyToUse: boolean;
  inspectionRequestIdentifier: string;
  inspectionStatus: string;
  inspector: {
    id: string;
    fullName: string;
  };
  inspectionRejectReasons: {
    name: string;
    translatedName: string | null;
  }[];
  decisionComment: string;
}

export interface WorkingHours {
  from: string;
  to: string;
}

export interface DeliveryStageComment {
  id: string;
  comment: string;
}

export interface DeliveryStageDescription {
  id: string;
  description: string;
}

export interface DeliveryStageLongterm {
  stageComment: DeliveryStageComment;
  stageDescription: DeliveryStageDescription;
  date?: number;
  amount: number;
  workingHours: WorkingHours;
  materialId: string;
  destination: string;
  floorNumber: number;
  buildingFloorFrom: number;
  buildingFloorTo: number;
  unloadingMethodId: string;
  maxLength: string;
  palletized: boolean;
  polluted: boolean;
  load: string;
  loadUnit: string;
  depot: string;
  depotArea: string;
  estimatedUnloadingTime: number;
}

export interface DeliveryStep extends BasicModel {
  amountStep: number;
  code: string;
  date: string;
  deliveryId: string;
  from: string;
  id: string;
  subMaterials: SubMaterial[];
  to: string;
}

export interface Log {
  message: string;
  date: string;
}

export enum VerificationStatus {
  Loading = 'loading',
  Error = 'error',
  OneDelivery = 'one-delivery',
  Deliveries = 'deliveries',
}

export interface AddDeliveryMaterialValues {
  formValue: AddDeliveryMaterialForm;
  materials?: Material[];
  identifiers?: string[];
  producers?: MaterialProducer[];
  suppliers?: MaterialSupplier[];
}

export interface DeliveryFormedFilled {
  formValue: NewDeliveryOther;
  propose: Propose[];
  times: TimeProposal[];
  type: DeliveryTypeDetail;
  addDeliveryMaterialValues: AddDeliveryMaterialValues[];
}

export interface CheckedDeliveries {
  deliveries?: CheckedDelivery[];
  trafficMarshall?: { data: string };
  verificationStatus?: VerificationStatus;
}

interface BusyObjectData {
  available: false;
  contains: false;
}

export interface CheckedDelivery {
  delivery: Delivery;
  limitVehicle: { data: string; current: boolean };
  toCheck: ToCheckDelivery;
  busyObjects: {
    crane: BusyObjectData;
    elevator: BusyObjectData;
    excavator: BusyObjectData;
    forklift: BusyObjectData;
    miniExcavator: BusyObjectData;
    place: BusyObjectData;
  };
}

export interface ToCheckDelivery {
  deliveryType: ToCheckDeliveryProperty;
  dateUnloading: ToCheckDeliveryProperty;
  identificator: ToCheckDeliveryProperty;
  registrationNumber: ToCheckDeliveryProperty;
  contract: ToCheckDeliveryProperty;
  gate: ToCheckDeliveryProperty;
  combined: ToCheckDeliveryProperty;
  direction: ToCheckDeliveryProperty;
  materials: ToCheckDeliveryProperty;
  code: ToCheckDeliveryCode;
  company: ToCheckDeliveryProperty;
  description: ToCheckDeliveryProperty;
  limitVehicles?: ToCheckDeliveryProperty;
}

export interface ToCheckDeliveryProperty {
  data: any;
  correct: boolean;
  shortName?: string;
}

export interface ToCheckDeliveryCode extends ToCheckDeliveryProperty {
  data: string | string[];
}

export interface Settings {
  bufferPark: boolean;
  bufferParkCoordinates?: { latitude: string; longitude: string };
  centralWarehouse: boolean;
  clockFormat: '12h' | '24h';
  constructionSiteAddress: string;
  currency: string;
  deliveryDays: number[];
  generalContractorCompany: string;
  generalContractorEmail: string;
  generalContractorFullName: string;
  generalContractorPhone: string | number;
  id: string;
  investorCompany: string;
  investorEmail: string;
  investorFullName: string;
  investorPhone: string | number;
  language: string;
  phonePrefix: string;
  projectName: string;
  unitSystem: string;
  workFrom: string;
  workInterval: number;
  workTo: string;
  streetConstructionSite: string;
  postCodeConstructionSite: string;
  prefixsPhone: { [key: string]: string };
  futureConstructionSite: number;
  startConstructionSite: string;
  localeString: string;
  logo: string;
  visualisation: string;
  appUrl: string;
  availableLanguages: { key: string; translationKey: string }[];
  projectSite: string;
  hourToSendMaterialEmail: number;
  marRequired: boolean;
  marPrefix: string;
  marFormat: string;
  marDelimiter: string;
  enabledSMS: boolean;
  enabledAddingMaterialCategories: boolean;
  firstDayOfWeek: string;
  restrictedDistributorPhones: boolean;
  constructionSiteCoordinates: { latitude: string; longitude: string };
  unloadingTimes: {
    fast: { label: string; value: number }[];
    other: { label: string; value: number }[];
  };
  // 0 - don't show tooltip
  // 1 - show only SUB company name and time
  // 2 - show all
  // https://propergate.atlassian.net/browse/PGAV-4227
  schedulerDeliveryTooltipNotSubDeliveryDetails: number;
  schedulerDeliveryTooltipNotSubReservationsDetails: number;
  usingDriverApp: boolean;
  weekDays: {
    start: number;
    end: number;
  };
}

export interface DeliveryCardSection {
  header: string;
  name: string;
  phone: string;
}

export type GeneralSettings = Settings;

export interface ObjectSettingsSingle {
  manualReservation: boolean;
  serviceObject: boolean;
  maxServiceTimeInWork: number;
  maxServiceTimeAfterWork: number;
  maxReservationTimeInWork: number;
  maxReservationTimeAfterWork: number;
  availability: number;
  costOfUse: number;
  power: number;
  voltage: number;
}

export interface ObjectSettings {
  parkingPlace: ObjectSettingsSingle;
  unloadingPlace: ObjectSettingsSingle;
  bufferParking: ObjectSettingsSingle;
  crane: ObjectSettingsSingle;
  excavator: ObjectSettingsSingle;
  miniExcavator: ObjectSettingsSingle;
  elevator: ObjectSettingsSingle;
  gate: ObjectSettingsSingle;
  depot: ObjectSettingsSingle;
}

export interface SubcontractorSettings {
  availableDeliveryTypes: AvailableDeliveryTypes;
  communicationSMS: boolean;
  communicationApp: boolean;
  vehicles: string[];
  longtermCategory: string[];
}

export interface AvailableDeliveryTypes {
  onetime: boolean;
  fast: boolean;
  emergency: boolean;
}

export interface EcologySettings {
  c16MaxCapacity: number;
  c16Time: string;
  c10MaxCapacity: number;
  c10Time: string;
  c05MaxCapacity: number;
  c05Time: string;
  monitoringCo2: boolean;
  vehiclesFromStorage: boolean;
  vehiclesToStorage: boolean;
  businessCar: boolean;
  madeMaterials: boolean;
  vehicles: {
    engine: string;
    co2: string;
    generalType: string;
    detailTypeVehicle: string;
  }[];
  carbonFootprint: boolean;
  exportWaste: boolean;
  usedEnergy: boolean;
  usedWater: boolean;
  usedFuel: boolean;
}

export interface Construction extends BasicModel {
  id: string;
  userId: string;
  constructionSiteObjects?: ConstructionObject[];
  possibleUpdate: boolean;
  from: string;
  to: string;
  backgroundUrl: string;
  screenShotUrl: string;
  generatedScreen: boolean;
  planDimensions?: ConstructionPlanDimensions;
}

export interface ConstructionObject extends BasicModel {
  id: string;
  type: string;
  text: string;
  svgType: string;
  width: number;
  height: number;
  x: number;
  y: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  points: any[];
  orientation: string;
  constructionSiteId: string;
  objectId: string;
  direction: string;
  directionPosition: { transform: string; x: string; y: string };
  hotspot: boolean;
  textPosition: { transform: string; x: string; y: string };
  transform: string;
}

export interface ConstructionPlanDimensions {
  dimensionsLock?: boolean;
  height: number;
  width: number;
}

export interface BusyObjectTime {
  reservationId?: string;
  expectedFrom: string;
  expectedTo: string;
  realTimeFrom: string;
  realTimeTo: string;
  companyName: string;
  deliveryCode: string;
  deliveryComment: string;
  status: string;
  type: string;
  deliveryId: string;
  materials: DeliveryMaterial[];
  deliveryNumber: number;
  deliveryType: DeliveryType;
  active: boolean;
  archived: boolean;
  deliveryEmergency: boolean;
  deliveryManual: boolean;
  deliveryStatus: string;
  deliveryExpectedUnloadingStart: Date;
  deliveryExpectedUnloadingTime: number;
  departureDate: Date;
  depotId: string;
  depth: string;
  destination: string;
  details: string;
  elevatorId: string;
  excavator: boolean;
  forklift: boolean;
  height: string;
  hotspot: boolean;
  maxUnloadingTimeInWork: number;
  maxUnloadingTimeNotWork: number;
  miniExcavator: boolean;
  number: number;
  placeDeliveryTimes: PlaceDeliveryTime[];
  subcontractorName: string;
  userId: string;
  width: string;
  slotType: string;
  subcontractor: {
    companyCode: string;
    companyName: string;
    scheduleColor: {
      backgroundColor: string;
      textColor: string;
      borderColor: string;
    };
  };
  materialCategory?: MaterialCategory;
  objectId?: string;
  totalWeight?: number;
  packageInfo?: {
    depth: number;
    height: number;
    weight: number;
    width: number;
  };
  bufferParkingEntryDate?: string;
  bufferParkingDepartureDate?: string;
  entryDate?: Date;
  driverRecallsCounter?: number;
  restrictedData?: boolean;
  repeatedFromDeliveryId?: string;
  repeatedFromDeliveryNumber?: number;
}

export interface BusyObjectWorkingHours {
  from: string;
  to: string;
  dayNumber?: number;
}

export interface BusyObject extends BasicModel {
  id: string;
  shortName: string;
  times: BusyObjectTime[];
  type: string;
  workingHours: BusyObjectWorkingHours[];
  iconName?: string;
}

export interface ProjectSettings {
  projectName: string;
  projectDescription: string;
  logo: string;
  visualisation: string;
  build: string;
  versionApp: string;
  defaultLanguageApp: string;
  projectLogo: string;
  timezone: string;
}

export interface Report extends BasicModel {
  startDate: string;
  endDate: string;
  deliveries: Delivery[];
  data: {
    co2ByRange?: { co2: number; dateUnloading: string }[];
    co2ByVehicle?: {
      co2: number;
      distanceFromPlace: number;
      vehicle: string;
    }[];
    longTimeDeliveries?: { all: number; materials: Material[] };
    oneTimeDeliveries?: { done: number; expected: number; all: number };
    subcontractorsDeliveries?: {
      allCount: number;
      companyName: string;
      doneDeliveriesCount: number;
      expectedDeliveriesCount: number;
    }[];
  };
  meta: {
    count: { count: number };
    page: string;
    pageSize: string;
  };
}

export interface ReportQueries {
  type: 'range' | 'now' | 'today';
  reportType: 'breeam' | 'general' | 'delay' | 'unrealized' | 'storage';
}

export interface DeliverySettings extends BasicModel {
  id: string;
  bookingTime: string;
  giveDetails: number;
  forWeekendAndMonday: number;
  acceptAndRejectTimeFrom: string;
  acceptAndRejectTimeTo: string;
  scheduleSlot: number;
  ignoreCompleteDeliveryData: boolean;
  averageTimeUnloading: number;
  allowedTrafficInCity: string[];
  automaticAcceptOneTimeDelivery: boolean;
  penaltyNoDelivery: number;
  penaltyDelayDelivery: number;
  penaltyTooLongUnloading: number;
  rejectionTimeWork: number;
  rejectionTimeNotWork: number;
  notArrivedNotification: number;
  resignationTime: number;
  sendCard: number;
  maxDelayNotArrived: number;
  deliveryFrom: string;
  deliveryTo: string;
  requiredData: string[];
  autoAcceptLongTime: boolean;
  autoAcceptOneTime: boolean;
  maxCapacityVehicle: number;
  combined: boolean;
  sourceInfoMaterial: string;
  deliveryCardSections: DeliveryCardSection[];
  uploadDeliveryNoteOnGate: boolean;
  uploadDeliveryVehicleOnGate: boolean;
}

export interface Propose {
  unloadingTime?: number;
  place: string;
  placeId: string;
  placeNumber: number;
  times: string[];
  excavatorId: string;
  type?: string;
  elevatorId: string;
  depotId: string;
  craneId: string;
  forkliftId: string;
  miniExcavatorId: string;
  mobileCraneId: string;
}

export interface TimeProposal extends Propose {
  time: string;
  timeId: string;
}

export interface Dashboard extends BasicModel {
  onSiteDeliveries?: Delivery[];
  expectedDeliveries?: Delivery[];
  reservations?: EODashboardDelivery[];
  doneDeliveriesByHour: { hour: number; deliveries: number }[];
  busyPlacesByHour?: { hour: number; places: number }[];
  deliveriesByHour?: { hour: number; deliveries: number }[];
  longTimeDeliveries: { done: number; all: number; expected: number };
  oneTimeDeliveries: { done: number; all: number; expected: number };
  allDeliveries: { done: number; all: number };
  allPlaces: { free: number; all: number };
  rejectedDeliveriesCount: number;
  updateRequiredDeliveriesCount: number;
  duringUnloadingDeliveries?: Delivery[];
  deliveriesOnConstructionSite?: number;
  delayedEntranceDeliveriesCount?: number;
  delayedUnloadingDeliveriesCount?: number;
  duringUnloadingDeliveriesLimit?: number;
  loading?: boolean;
  deliveredMaterial?: number;
  orderedMaterial?: number;
  onSiteDeliveriesRuns?: DeliveryRun[];
  infoButtons?: {
    deliveriesExpectedToday: number;
    deliveriesExpectedWeek: number;
    deliveriesRunsInProgress: number;
  };
}

export interface EODashboardDelivery {
  id: string;
  delivery: Delivery;
  expectedFrom: string;
  expectedTo: string;
  materialCategory: MaterialCategory;
  responsiblePerson: User;
  slotType: 'delivery' | 'reservation';
  subcontractor: Subcontractor;
  realTimeFrom: string;
  realTimeTo: string;
  locationSource: any;
  locationDestination: any;
  unloadingIntervals: { start: Date | string; stop: Date | string }[];
}

export interface DashboardPreview {
  lastUpdate: string;
}

export interface DashboardCanteen {
  building: Building;
  duringUnloadingDeliveries: Delivery[];
  duringUnloadingDeliveriesLimit: number;
  expectedDeliveries: Delivery[];
  expectedDeliveriesAmount: number;
  doneDeliveriesLength: number;
  concrete: { expected: number; done: number };
  steel: { expected: number; done: number };
}

export interface DashboardTV {
  buildingsDashboard: {
    building: Building;
    dashboardData: {
      id: string;
      startDate: string;
      materials: {
        spoil: { progress: number; estimated: number };
        steel: { progress: number; estimated: number };
        facade: { space: number; progress: number; pieChartData: any };
        concrete: { progress: number; estimated: number };
        construction: { height: number; progress: number; pieChartData: any };
      };
      actualFloor: number;
      maxFloor: number;
      createdAt: string;
      updatedAt: string;
      buildingId: string;
    };
    dashboards: {
      actualFloor: number;
      buildingId: string;
      createdAt: string;
      id: string;
      materials: {
        [key: string]: {
          progress: number;
          estimated?: number;
          height?: number;
          space?: number;
        };
      };
      maxFloor: number;
      startDate: string;
      updatedAt: string;
    }[];
  }[];
  chart: {
    materials: {
      concrete: { estimated: string; progress: string };
      spoil: { estimated: string; progress: string };
      steel: { estimated: string; progress: string };
    };
    startDate: string;
  }[];
  fromStartConstructionSite: { spoil: number; concrete: number; steel: number };
}

export interface Unit {
  value: string;
  translation: string | Translation;
  id?: string;
  name?: string;
  translatedName?: string;
}

export interface VehicleSize extends BasicModel {
  id: string;
  name: string;
  translation: string;
  iconName: string;
}

export interface PlaceDeliveryTime extends BasicModel {
  id: string;
  from: string;
  to: string;
  placeId: string;
}

export interface Translations {
  [key: string]: { [key: string]: string };
}

export interface WeeklyScheduleDate {
  nameOfDay: string;
  shortDate: string;
  dateAsValue: number;
}

export interface WeeklyScheduleData {
  [key: number]: {
    general: WeeklyScheduleDataGeneral;
    stages: WeeklyScheduleDataStage[];
  };
}

export interface WeeklyScheduleDataGeneral {
  amount: number;
  date: Date;
  drives: number;
  showDetails: boolean;
  stagesTotal: number;
}

export interface WeeklyScheduleDataStage {
  code: string;
  destinationName: string;
  from: string;
  to: string;
  details: {
    amount: string;
    name: string;
    runs: string;
    unload: string;
  };
}

export interface AvailableMaterialCategory extends MaterialCategory {
  translatedName: string;
  contract: Contract;
}

export interface GeneralAmounts {
  amount: number;
  drives: number;
  stages: number;
  unit: string;
}

export interface FullDayData {
  dayTimestamp: number;
  dayDateFormatted: string;
  dayNameLabel: string;
  deliveryDayStatus: boolean;
  generalAmounts: GeneralAmounts;
}

export interface StagesFormData {
  availableDepotsOfCategory: MaterialDepot[];
  availableMaterialsOfCategory: MaterialCategory;
  deliveryStages: DeliveryStage[];
  floorsRange: number[];
  fullDayData: FullDayData;
  materialCategoryObj: AvailableMaterialCategory;
  subcontractorId: string;
}

export interface DateObj {
  year: number;
  month: number;
  day: number;
}

export interface LongtermDeliveryDataForm extends Partial<Delivery> {
  materialCategoryId?: string;
  dateUnloadingInPicker: {
    date: DateObj;
  };
  material: {
    id?: string;
    materialCategoryId: string;
    producer: MaterialProducer;
    supplier: MaterialSupplier;
  };
  dateUnloading: any; // because TS 2.5.3 has no Omit or Exclude
  distributor: any; // because TS 2.5.3 has no Omit or Exclude
}

export interface StageDateData {
  date: number;
  status: boolean;
  dayIndex: number;
}

export interface UpdatedStagesData {
  date: number;
  stages: DeliveryStage[];
}

export interface DatePickerData {
  date: DateObj;
  epoc: number;
  formatted: number;
  jsdate: Date;
}

export interface LongtermStageAddItem {
  item: any;
  collectionName: string;
  collectionId: string;
}

export interface MaterialsComment {
  comment: string;
  id: string;
  materialId?: string;
  createdById?: string;
}

export interface MaterialsComments {
  [key: string]: MaterialsComment[];
}

export interface MaterialsDescription {
  description: string;
  id: string;
  materialId?: string;
  createdById?: string;
}

export interface MaterialsDescriptions {
  [key: string]: MaterialsDescription[];
}

export interface CreateInspectionResponse {
  inspectionResults: DeliveryMaterial[];
  deliveryInspectionStatus: InspectionStatusesKeys;
}

export interface CreateInspectionSuccessPayload {
  deliveryMaterial: DeliveryMaterial;
  deliveryInspectionStatus: InspectionStatusesKeys;
}

export interface SubcontractorsEmployee extends BasicModel {
  id?: string;
  defaultEmployee?: boolean;
  subcontractorEmployeeRole?: {
    id: string;
    name: string;
    type: string;
    translationId: string;
    createdAt: string;
    updatedAt: string;
  };
  subcontractorEmployeeRoleId?: string;
  user?: User;
}

export interface SubcontractorsEmployeeRole extends BasicModel {
  id: string;
  name: string;
  translation: Record<string, unknown>;
  translationId: string;
  type: string;
}

export interface AssignEmployeeBody {
  defaultEmployee: boolean;
  subcontractorEmployeeRoleId: string;
}

export interface LocationType extends BasicModel {
  id: string;
  name: string;
  translationId: string;
}

export interface DispatchStageActionPayload {
  deliveryId: string;
  stageId: string;
  dispatchedAction: POSSIBLE_STAGE_ACTIONS;
}

export interface SendTruckPayload {
  supplierId: string;
  locationId: string;

  vehicleId: string;
  driverId: string;
  deliveryTicketId: string;
  quantity: number;

  deliveryId: string;
  stageId: string;
  stageStatus: DELIVERY_STAGE_STATUS;
}

export interface DeliverySetTimesBody {
  batchStart?: Date | number;
  deadlineToUseTill?: Date | number;
  unloadingStart?: Date | number;
  unloadingFinish?: Date | number;
  departedFromSite?: Date | number;
  actions: DeliverySetTimeActions[];
}

export interface RouterParams {
  path: any[];
  query?: Record<string, unknown>;
  extras?: NavigationExtras;
  alertInfo?: string;
  alertError?: string;
  openDetailsPanel?: {
    opened: boolean;
    type: DeliveryType;
    status: DeliveryFrontStatus;
    id: string;
  };
}
