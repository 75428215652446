<div [formGroup]="form"
     class="material-form">
  <div class="form__input-container load">
    <p class="title">{{ 'deliveries_page.delivery_direction_l' | translate }}</p>
    <div class="form__input form__input--50 form__input--checkbox">
      <input [id]="'import-' + index"
             formControlName="import"
             type="checkbox">
      <label [for]="'import-' + index">{{ 'deliveries_page.import_a' | translate }}</label>
    </div>
    <div class="form__input form__input--50 form__input--checkbox">
      <input [id]="'export-' + index"
             formControlName="export"
             type="checkbox">
      <label [for]="'export-' + index">{{ 'deliveries_page.export_a' | translate }}</label>
    </div>
  </div>

  <label [ngClass]="{'disabled': materialCategoryFieldIsDisabled }" >{{ 'add_delivery.category_l' | translate }} *</label>
  <div class="form__select form__input--100 form__input--remove"
       [ngClass]="{'disabled': materialCategoryFieldIsDisabled }"
  >
    <div>
      <ng-select [clearable]="false"
                 [items]="categories"
                 [notFoundText]="'general.no_items_found_l' | translate"
                 [searchFn]="categorySearchFn"
                 bindLabel="label"
                 bindValue="id"
                 formControlName="materialCategoryId"
                 test-id="materialCategoryDropdown">
      </ng-select>
    </div>
    <button (click)="remove()"
            class="remove"
            [ngClass]="{'disabled': materialCategoryFieldIsDisabled }"
    >
      <svg-icon name="icon-remove"
                size="16"></svg-icon>
    </button>
  </div>
  <div class="error-message relative error-margin"
                test-id="errorMessage"
                *ngIf="hasError('materialCategoryId', 'required')">
               {{ 'validation.field_required_l' | translate }}
  </div>

  <label *ngIf="marRequired">{{ 'add_delivery.identifier_l' | translate }} *</label>
  <div *ngIf="marRequired"
       class="form__select form__input--100">
    <div>
      <ng-select (change)="identifierChanged($event)"
                 [clearable]="false"
                 [items]="identifiers"
                 [notFoundText]="'general.no_items_found_l' | translate"
                 formControlName="marIdentifier">
      </ng-select>
    </div>
  </div>

  <label [ngClass]="{'disabled': materialCategoryFieldIsDisabled }">{{ 'add_delivery.product_l' | translate }} *</label>
  <div class="form__select form__input--100"
       [ngClass]="{'disabled': materialCategoryFieldIsDisabled }"
  >
    <div>
    <div>
        <ng-select (change)="materialChange($event)"
                 [clearable]="false"
                 [notFoundText]="'general.no_items_found_l' | translate"
                 [searchFn]="productSearchFn"
                 formControlName="materialId"
                 test-id="productNameDropdown">
          <ng-option *ngFor="let material of materials | sortBy: 'translatedName'"
                     [value]="material.id">{{ material.groupedMaterials[0] | translateFromObject }}
                     <!-- ({{ 'general.items_left' | translate }} {{ material.maxAmount-material.amountDelivered }} {{ 'general.of' | translate }} {{ material.maxAmount }} {{ material.groupedMaterials[0].unit | translateUnit }}) -->
          </ng-option>
        </ng-select>
      </div>
      <div class="error-message"
            test-id="errorMessage"
            *ngIf="hasError('materialId', 'required')">
            {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <label>{{ 'add_delivery.producer_l' | translate }}</label>
  <div class="form__select form__input--100">
    <div class="disabled">
      <ng-select [items]="producers$ | async"
                 bindLabel="name"
                 bindValue="id"
                 formControlName="producerId">
      </ng-select>
    </div>
  </div>
  <label>{{ 'add_delivery.supplier_l' | translate }} *</label>
  <div class="form__select form__input--100">
    <div>
      <ng-select [clearable]="false"
                 [items]="suppliers$ | async"
                 [notFoundText]="'general.no_items_found_l' | translate"
                 bindLabel="name"
                 bindValue="id"
                 formControlName="supplierId"
                 test-id="supplierNameDropdown">
      </ng-select>
    </div>
    <div class="error-message"
            test-id="errorMessage"
            *ngIf="hasError('supplierId', 'required')">
            {{ 'validation.field_required_l' | translate }}
    </div>
  </div>

  <div class="form__input-container load">
    <div class="form__input form__input--50">
      <label>{{ 'deliveries_page.cargo_amount_l' | translate }} *</label>
      <input formControlName="expectedAmount"
             test-id="materialQuantityInput"
             type="number">
      <div class="error-message"
            test-id="errorMessage"
            *ngIf="hasError('expectedAmount', 'required')">
             {{ 'validation.field_required_l' | translate }}
      </div>
      <div class="error-message"
           test-id="errorMessage"
          *ngIf="hasError('expectedAmount', 'min')">
        {{ 'validation.field_min_no_value_l' | translate }} 0
      </div>
      <div class="error-message"
           test-id="errorMessage"
          *ngIf="hasError('expectedAmount', 'max')">
        {{ 'validation.field_max_no_value_l' | translate }} 999999.99
      </div>
    </div>
    <div class="form__select form__input--50">
      <label>{{ 'deliveries_page.measure_l' | translate }} *</label>
      <div>
        <ng-select [clearable]="false"
                   [searchable]="false"
                   formControlName="unit"
                   test-id="unitDropdown">
          <ng-option *ngFor="let unit of units$ | async"
                     [value]="unit | translateFromObject: { specificLang: 'en' }">{{ unit.name | translateUnit }}</ng-option>
        </ng-select>
      </div>
      <div class="error-message"
            test-id="errorMessage"
            *ngIf="hasError('unit', 'required')">
             {{ 'validation.field_required_l' | translate }}
      </div>
    </div>
  </div>

  <div class="form__textarea form__input--100">
    <label>{{ 'deliveries_page.remarks_l' | translate }}</label>
    <textarea formControlName="remarks"></textarea>
  </div>

</div>
