import { isFunction } from 'lodash-es';

import * as AuthActions from './auth.actions';
import { AuthState } from './';
import { TokenDecoded } from '@app/core/http/interfaces';
import { config } from '@app/app.config';
import jwt_decode from 'jwt-decode';
import { ReducerMap } from '../interfaces';

export type AuthAction = AuthActions.AuthActions;

const getInitialState = (): AuthState => {
  const cookieMatch = document.cookie.match(new RegExp(`(^| )${config.storageTokenKey}=([^;]+)`));
  const token = cookieMatch ? cookieMatch[2] : null;
  return {
    token,
    tokenDecoded: token && (jwt_decode(token) as TokenDecoded),
    error: false,
  };
};

const reducerMap: ReducerMap<AuthState> = {
  [AuthActions.USER_LOGIN_SUCCESS]: (state, { token, tokenDecoded }) => ({
    ...state,
    token,
    tokenDecoded,
    error: false,
  }),
  [AuthActions.USER_LOGIN_FAIL]: (state) => ({
    ...state,
    token: '',
    tokenDecoded: null,
    error: true,
  }),
  [AuthActions.USER_SIGN_OUT_SUCCESS]: (state) => ({
    ...state,
    token: '',
    tokenDecoded: null,
    error: false,
  }),
  [AuthActions.USER_SIGN_OUT_FAIL]: (state) => ({
    ...state,
    token: '',
    tokenDecoded: null,
    error: true,
  }),
  [AuthActions.SET_REDIRECTION_LINK]: (state, { redirectUrl, queryParams }) => ({
    ...state,
    redirectUrl,
    queryParams,
  }),
  [AuthActions.REFRESH_AUTH_TOKEN_SUCCESS]: (state, { token, tokenDecoded }) => ({
    ...state,
    token,
    tokenDecoded,
    error: false,
  }),
  [AuthActions.REFRESH_AUTH_TOKEN_FAIL]: (state) => ({
    ...state,
    error: true,
  }),
};

export function authReducer(state: AuthState = getInitialState(), action: any): AuthState {
  return isFunction(reducerMap[action.type]) ? reducerMap[action.type](state, action.payload) : state;
}
