import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { config } from '@app/app.config';
import { QueryParams, QueryParamObject } from '@app/core/http/interfaces';
import { GenericForm } from '@app/shared/@interfaces';
import { FormService } from '@app/shared/@services/form.service';
import * as fromState from '@app/state';
import {
  AddDeliveryMaterialValues,
  GroupedMaterialAsProduct,
  Material,
  MaterialCategory,
  MaterialProducer,
  MaterialSupplier,
  Unit,
} from '@app/state/interfaces';
import { Store } from '@ngrx/store';
import { get, omit } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SortingOrder } from '@app/shared/search/search.service';

export interface AddDeliveryMaterialForm {
  materialCategoryId: string;
  materialId: string;
  expectedAmount: number | string;
  unit: string;
  producerId: string;
  supplierId: string;
  remarks: string;
  marIdentifier: string;
  import?: boolean;
  export?: boolean;
}

@Component({
  selector: 'app-add-delivery-material',
  templateUrl: './add-delivery-material.component.html',
  styleUrls: ['./add-delivery-material.component.scss'],
})
export class AddDeliveryMaterialComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public categories: MaterialCategory[] = [];
  @Input() public initValues: AddDeliveryMaterialValues;
  @Input() public subcontractorId: string;
  @Input() public hasSubcontractorInputPermission: boolean;
  @Input() public index: number;

  @Output() public removeMaterial = new EventEmitter<void>();

  public form: GenericForm<AddDeliveryMaterialForm>;
  public destroy$ = new Subject<void>();
  public materials: GroupedMaterialAsProduct[] = [];
  public allMaterials: Material[] = [];
  public identifiers: string[] = [];
  public category: MaterialCategory;
  public material: Material;
  public producers$: Observable<MaterialProducer[]>;
  public suppliers$: Observable<MaterialSupplier[]>;
  public units$: Observable<Unit[]>;
  public subcontractorId$ = new BehaviorSubject<string>(null);
  public marRequired = false;
  public maxMaterialAmount = 999999.99;
  public materialCategoryFieldIsDisabled = false;

  private isMarRequired: boolean;

  constructor(private store: Store<fromState.AppState>, private formService: FormService) {}

  public ngOnInit() {
    this.createForm();
    this.initCategoryChangeStream();
    this.setProducersAndSuppliers();
    this.setInitValues();
    this.setUnits();
    this.setSupplier();

    if (!this.hasSubcontractorInputPermission && !this.subcontractorId) {
      this.form.get('materialCategoryId').disable();
      this.form.get('materialId').disable();
    }

    this.materialCategoryFieldIsDisabled = this.form.get('materialCategoryId').status === 'DISABLED';

    this.store
      .select(fromState.getAllSettings)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((settings) => {
        this.isMarRequired = settings.marRequired;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const value: string = changes.subcontractorId?.currentValue;

    if (value && this.subcontractorId$ && this.form) {
      if (!this.hasSubcontractorInputPermission) {
        this.form.get('materialCategoryId').enable();
        this.form.get('materialId').enable();
        this.materialCategoryFieldIsDisabled = this.form.get('materialCategoryId').status === 'DISABLED';
      }

      this.subcontractorId$.next(value);
    }

    if (this.subcontractorId && changes.categories?.currentValue && this.form) {
      const categoryValue = this.form.get('materialCategoryId').value;

      if (!categoryValue && this.categories.length === 1) {
        this.form.get('materialCategoryId').patchValue(this.categories[0]?.id);
      }
    }
  }

  public setInitValues() {
    if (this.initValues) {
      this.form.patchValue(this.initValues.formValue);
    }
  }

  public materialChange(materialId: string) {
    const material = this.allMaterials.find((material) => material.id === materialId);
    const producerId: string = get(material, 'producerId', null);

    this.form.get('producerId').setValue(producerId);
    this.form.get('marIdentifier').setValue(material.marIdentifier);

    if (!this.isMarRequired) {
      this.marRequired = false;
      return;
    }

    this.marRequired = get(
      config.materialDurabilities.find(({ value }) => value === material.durability),
      'marRequired'
    );
  }

  public categorySearchFn(filterValue: string, category: MaterialCategory): boolean {
    const latinize = (value: string) => value.normalize('NFD').replace(/[\u0300-\u036f]|\W|/g, '');
    const regExpToTest = new RegExp(latinize(filterValue), 'i');
    const nameTest = regExpToTest.test(latinize(category.name));
    const tagsTest = regExpToTest.test(latinize(category.tagsList));
    return nameTest || tagsTest;
  }

  public productSearchFn = (filterValue: string, materialId: string) => {
    const latinize = (value: string) => value.normalize('NFD').replace(/[\u0300-\u036f]|\W|/g, '');
    const regExpToTest = new RegExp(latinize(filterValue), 'i');
    const filteredMaterial = this.allMaterials.find((material) => material.id === materialId);
    const nameTest = regExpToTest.test(latinize(filteredMaterial.translatedName));
    return nameTest ? true : false;
  };

  public identifierChanged(marIdentifier: string) {
    const material = this.allMaterials.find((material) => material.marIdentifier === marIdentifier);

    if (material) {
      this.form.get('materialId').setValue(material.id);
      this.form.get('producerId').setValue(material.producerId);
    }
  }

  public checkValid(): boolean {
    this.formService.validateAllFormFields(this.form);
    return this.form.valid;
  }

  public getData() {
    return this.checkValid() ? omit(this.form.getRawValue(), 'marIdentifier') : null;
  }

  public remove() {
    this.removeMaterial.emit();
  }

  private setSupplier() {
    this.suppliers$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((suppliers) => {
      const supplierControl = this.form.get('supplierId');
      const supplierValue = supplierControl.value;
      if (!supplierValue && suppliers.length === 1) supplierControl.patchValue(suppliers[0]?.id);
    });
  }

  private setUnits() {
    this.store.dispatch(new fromState.GetUnits());
    this.units$ = this.store.select(fromState.getAllUnits);
  }

  private setProducersAndSuppliers() {
    const getSuppliers = ([suppliers, isLogged]: [MaterialSupplier[], boolean]) => {
      if (isLogged && !suppliers) {
        this.store.dispatch(new fromState.GetSuppliers([{ type: QueryParams.NoLimit }, 
          {
            type: QueryParams.Order,
            filterField: 'name',
            condition: SortingOrder.ASC,
          },
        ]));
      }
    };

    const getProducers = ([producers, isLogged]: [MaterialProducer[], boolean]) => {
      if (isLogged && !producers) {
        this.store.dispatch(new fromState.GetProducers([{ type: QueryParams.NoLimit }]));
      }
    };

    this.suppliers$ = this.store.select(fromState.getSuppliers).pipe(
      withLatestFrom(this.store.select(fromState.isLoggedIn)),
      tap(getSuppliers),
      map(([suppliers]) => suppliers)
    );
    this.producers$ = this.store.select(fromState.getProducers).pipe(
      withLatestFrom(this.store.select(fromState.isLoggedIn)),
      tap(getProducers),
      map(([producers]) => producers)
    );
  }

  private initCategoryChangeStream() {
    this.subcontractorId$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      if (id) {
        this.form.get('materialCategoryId').patchValue(null);
      }
    });

    combineLatest([this.subcontractorId$.asObservable(), this.form.get('materialCategoryId').valueChanges])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([subcontractorId, categoryId]) => {
        this.getCategoryMaterials(subcontractorId, categoryId);
        this.form.get('marIdentifier').setValue(null);
        this.form.get('materialId').setValue(null);
        this.form.get('producerId').setValue(null);
      });

    this.store
      .select(fromState.getMaterialCategoriesDetails)
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((categories) => {
        const category = categories[this.form.getRawValue().materialCategoryId];

        if (category) {
          let categoryMaterials = category.materials;

          if (this.subcontractorId) {
            categoryMaterials = categoryMaterials.filter(({ subcontractors }) =>
              subcontractors.some(({ id }) => id === this.subcontractorId)
            );
          }

          this.allMaterials = categoryMaterials;
          this.materials = categoryMaterials
            .reduce((group, material) => {
              const sameMaterial = (item: GroupedMaterialAsProduct) => {
                return item.name === material.name && item.materialCategoryId === material.materialCategoryId && item.producerId === material.producerId
              }
              const element = group.find(sameMaterial);
              const maxAmount = material.maxAmount || 0;

              if (element) {
                element.maxAmount += maxAmount;

                if (material.status === 'DELIVERED') {
                  element.amountDelivered += maxAmount;
                }

                element.groupedMaterials.push(material);

                group.map((item) => (sameMaterial(item) ? element : item));
              } else {
                group.push({
                  id: material.id,
                  name: material.name,
                  label: material.name,
                  translatedName: material.translatedName,
                  maxAmount,
                  amountDelivered: material.status === 'DELIVERED' && material.maxAmount ? material.maxAmount : 0,
                  groupedMaterials: [{ ...material }],
                  producerId: material.producerId,
                });
              }

              return group;
            }, [])

          this.identifiers = category.materials
            .map((material) => material.marIdentifier)
            .filter(Boolean)
            .sort((idA, idB) => idA.localeCompare(idB));

          const savedMaterialId = this.initValues?.formValue?.materialId;

          if (savedMaterialId && category.materials.find((material) => material.id === savedMaterialId)) {
            this.form.get('materialId').patchValue(savedMaterialId);
          }

          if (!savedMaterialId && this.materials.length === 1) {
            const materialId = this.materials[0].id
            this.form.get('materialId').patchValue(materialId);
            this.materialChange(materialId)

          }
        }
      });
  }

  private getCategoryMaterials(subcontractorId: string, id: string) {
    if (!id) {
      return;
    }

    this.store.dispatch(new fromState.GetMaterialCategory({ id }));
  }

  public hasError(name: string, errorType: string): boolean {
    return this.form.get(name).hasError(errorType) && this.form.get(name).touched;
  }

  private createForm() {
    this.form = this.formService.initGroup({
      materialCategoryId: [null, Validators.required],
      materialId: [null, Validators.required],
      expectedAmount: [null, [Validators.required, Validators.min(0), Validators.max(this.maxMaterialAmount)]],
      unit: [null, Validators.required],
      producerId: { value: null, disabled: true },
      supplierId: [null, Validators.required],
      marIdentifier: null,
      remarks: null,
      import: true,
      export: false,
    });
  }
}
