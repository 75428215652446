import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TranslationsGuard, AuthenticationGuard, SettingsGuard } from './core';

const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
  },
  {
    path: 'deliveries',
    loadChildren: () => import('./deliveries/deliveries.module').then((m) => m.DeliveriesModule),
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./scheduler/scheduler.module').then((m) => m.SchedulerModule),
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./suppliers/suppliers.module').then((m) => m.SuppliersModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'materials',
    loadChildren: () => import('./materials/materials.module').then((m) => m.MaterialsModule),
  },
  {
    path: 'construction-plan',
    loadChildren: () => import('./construction-plan/construction-plan.module').then((m) => m.ConstructionPlanModule),
  },
  {
    path: 'tv',
    loadChildren: () => import('./tv-dashboards/tv-dashboards.module').then((m) => m.TVDashboardsModule),
    canActivate: [TranslationsGuard, AuthenticationGuard, SettingsGuard],
  },
  {
    path: 'delivery-card/:id',
    loadChildren: () => import('./delivery-card/delivery-card.module').then((m) => m.DeliveryCardModule),
  },
  {
    path: 'objects',
    loadChildren: () => import('./objects/objects.module').then((m) => m.ObjectsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'manual-mode',
    loadChildren: () => import('./manual-mode/manual-mode.module').then((m) => m.ManualModeModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule),
  },
  {
    path: 'subcontractors',
    loadChildren: () => import('./subcontractors/subcontractors.module').then((m) => m.SubcontractorsModule),
  },
  {
    path: 'create-account',
    loadChildren: () => import('./create-account/create-account.module').then((m) => m.CreateAccountModule),
  },
  {
    path: 'new-password',
    loadChildren: () => import('./new-password/new-password.module').then((m) => m.NewPasswordModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
