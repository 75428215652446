
  export const environment = {
    production: true,
    version: '(env.dev)',
    apiUrl: 'https://studioa.propergate.app/api',
    apiNgUrl: 'undefined',
    appUrl: 'studioa.propergate.app',
    appNgUrl: 'studioa2.propergate.app',
    socketUrl: '',
    socketPath: '/api/socket.io',
    defaultTokenKey: 'token',
    defaultLanguage: 'en',
    enableFloorEditor: true,
    clientShortname: 'SKASTA',
    procoreIntegrationUrl: 'undefined',
    gcpApiKey: 'AIzaSyCb2rZE12HyiwiRXm0z3Ilo-eowKy5zauE',
    gcpProjectId: 'azure-migration-285408',
    clientLogoUrl: 'undefined'
  };