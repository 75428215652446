import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, filter, map } from 'rxjs/operators';
import { config } from '@app/app.config';
import { DeliveryFrontStatus, Filtering, TableHeader } from '@app/config/interfaces';
import { Delivery, DeliveryStage, RunPanelConfig, DeliveryMaterial } from '@app/state/interfaces';
import { SortingOrder, MaterialMappedForRequest, GenericFormGroup } from '@app/shared';
import * as state from '@app/state';
import { Store } from '@ngrx/store';
import { QueryParams, QueryParamObject } from '@app/core/http/interfaces';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash-es';
import { Actions, ofType } from '@ngrx/effects';
import { AbstractControl, Validators } from '@angular/forms';
import { PermissionsEnum, UserRoles, DeliveryTypeDetail, DeliveryType } from '@ppgt/web/shared/domain';
import { DeviceService } from '@app/core/device.service';

export enum DeliveriesHeader {
  Currently = 'deliveriesCurrently',
  CurrentlyLongterm = 'deliveriesCurrentlyLongterm',
  TodayOneTime = 'deliveriesTodayOneTime',
  TodayLongTerm = 'deliveriesTodayLongTerm',
  ExpectedOneTime = 'deliveriesExpectedOneTime',
  ExpectedLongTerm = 'deliveriesExpectedLongTerm',
  ToComplete = 'deliveriesToComplete',
  ToAccept = 'deliveriesToAccept',
  Completed = 'deliveriesCompleted',
  CompletedLongterm = 'deliveriesCompletedLongterm',
  Rejected = 'deliveriesRejected',
  All = 'deliveriesAll',
  DeliveryRuns = 'deliveryRuns',
}

export interface DeliveryDetails {
  opened: boolean;
  isUserAction?: boolean;
  id?: string;
  status?: DeliveryFrontStatus;
  type?: DeliveryType;
  isEditing?: boolean;
  restoreMode?: boolean;
}

interface LongtermPanelState {
  opened: boolean;
  deliveryDetails?: any;
  isEditing?: boolean;
  date?: number;
}

type LegendItem = { [key in DeliveryTypeDetail]?: { svgName: string; title: string } };

@Injectable({
  providedIn: 'root',
})
export class DeliveriesService implements OnDestroy {
  private proposalDateSource = new Subject<string>();
  public proposalDate$ = this.proposalDateSource.asObservable();
  private toggleDeliveryDetailsSource = new BehaviorSubject<DeliveryDetails>({ opened: false });
  private toggleAddLongtermDeliverySource = new BehaviorSubject<LongtermPanelState>({
    opened: false,
    deliveryDetails: null,
    isEditing: false,
  });
  public toggleDeliveryDetails$ = this.toggleDeliveryDetailsSource.asObservable();
  public toggleAddLongtermDelivery$ = this.toggleAddLongtermDeliverySource.asObservable();
  public panelOpened = false;
  public legendOnetime: LegendItem = {
    [DeliveryTypeDetail.Emergency]: { svgName: 'type-emergency', title: 'general.type_emergency' },
    [DeliveryTypeDetail.Fast]: { svgName: 'type-fast', title: 'general.type_fast' },
    [DeliveryTypeDetail.Normal]: { svgName: 'type-normal', title: 'general.type_normal' },
  };
  public legend: LegendItem = {
    [DeliveryTypeDetail.Longtime]: {
      svgName: 'type-longtime',
      title: 'general.type_longtime',
    },
    ...this.legendOnetime,
  };
  public dateSortingASC = [{ property: 'dateUnloading', order: SortingOrder.ASC }];
  public dateSortingDESC = [{ property: 'dateUnloading', order: SortingOrder.DESC }];
  private userRoleType: UserRoles;
  private grantedPermissions: PermissionsEnum[];

  private stagePanelOpenedSubject = new BehaviorSubject<boolean>(false);
  public deliveryOfStage: Delivery;
  public stage: DeliveryStage;
  public deliveryStatus: string;
  public runConfig: RunPanelConfig;
  public stageId: string;
  public stagePanelOpened$ = this.stagePanelOpenedSubject.asObservable();

  private sendTruckPanelOpenedSubject = new BehaviorSubject<boolean>(false);
  private deliveryRunPanelOpenedSubject = new BehaviorSubject<RunPanelConfig>({
    opened: false,
  });
  public sendTruckPanelOpened$ = this.sendTruckPanelOpenedSubject.asObservable();
  public deliveryRunPanelOpened$ = this.deliveryRunPanelOpenedSubject.asObservable();
  public rejectDeliveryAsideOpened$ = new BehaviorSubject<{
    id: number;
    deliveryId: string;
    redirectAfter?: boolean;
    opened: boolean;
  }>({
    opened: false,
    id: null,
    deliveryId: null,
    redirectAfter: false,
  });
  public onetimeDeliveryData: Delivery;
  public isOnetimeDeliveryFormEmpty = true;
  public onetimeDeliveryFormOpened$ = new BehaviorSubject<{ opened: boolean; isUserAction?: boolean }>({
    opened: false,
  });

  private destroy$ = new Subject<boolean>();

  constructor(
    private store: Store<state.AppState>,
    private translateService: TranslateService,
    private router: Router,
    private actions$: Actions,
    public device: DeviceService
  ) {
    this.store
      .select(state.getUserRole)
      .pipe(filter(Boolean))
      .subscribe((role) => {
        this.userRoleType = role.type;
        this.grantedPermissions = role.permits;
      });

    this.actions$
      .pipe(
        ofType(state.DISPATCH_STAGE_ACTION_SUCCESS, state.SEND_TRUCK_SUCCESS),
        takeUntil(this.destroy$),
        map((action: state.DispatchStageActionSuccess | state.SendTruckSuccess) => action.payload)
      )
      .subscribe((payload) => {
        // This is all due to the fact that angular doesn't see changes in objects automatically
        // thus we have to create new objects manually and pass them to stage-panel by ourself
        const { updatedDeliveryStage } = payload;
        const updatedStagesArray = this.deliveryOfStage.deliveryStages?.map((stage) =>
          stage === updatedDeliveryStage ? updatedDeliveryStage : stage
        );
        const updatedDeliveryOfStage = {
          ...this.deliveryOfStage,
          deliveryStages: updatedStagesArray,
        };

        // if sendTruckPanel openned close it - we can call it always without consequences
        this.closeSendTruckPanel();

        // provide new data to the stage details panel
        this.openStagePanel(updatedDeliveryOfStage, updatedDeliveryStage);
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
  }

  public getTableColumns(deliveriesProp: DeliveriesHeader, param?: any) {
    const tableHeader: TableHeader[] = config.tables[deliveriesProp];
    return tableHeader.filter(
      (item) =>
        (!item.requiredPermission || this.grantedPermissions.includes(item.requiredPermission)) &&
        (!item.params || item.params.includes(param))
    );
  }

  public getStatus(status: string) {
    const defaultObj = { translation: '', match: / /, status: '' as DeliveryFrontStatus };
    const statusObj = Object.values(config.deliveryStatuses).find((obj) => obj.match.test(status));

    return statusObj || defaultObj;
  }

  public getFiltering(deliveriesTypeField: DeliveriesHeader): Filtering[] {
    return config.filteringDeliveries[deliveriesTypeField].filter(
      ({ roles }: Filtering) => !roles || roles.includes(this.userRoleType)
    );
  }

  public mapDeliveries(deliveries: Delivery[]): Delivery[] {
    return deliveries
      ? deliveries.map((delivery) => {
          const deliveryStages = (delivery.deliveryStages || []).map((stage) => ({ ...stage, toggled: false }));
          return { ...delivery, deliveryStages, toggled: false };
        })
      : [];
  }

  public openAll(deliveries: Delivery[]): Delivery[] {
    if (!deliveries) {
      return [];
    }

    return deliveries.map((delivery) => {
      const deliveryStages = delivery.deliveryStages.map((stage) => ({ ...stage, toggled: true }));
      return { ...delivery, deliveryStages, toggled: true };
    });
  }

  public getRedirectPath(delivery: Delivery) {
    const deliveryFrontStatus = this.getStatus(delivery.status).status;
    const { isMobile } = this.device;

    if (deliveryFrontStatus === DeliveryFrontStatus.Expected && delivery.typeDelivery === DeliveryType.Longtime) {
      return 'deliveries/expected/longterm';
    }

    if (deliveryFrontStatus === DeliveryFrontStatus.Expected && delivery.typeDelivery === DeliveryType.Onetime) {
      return 'deliveries/expected/onetime';
    }

    if (deliveryFrontStatus === DeliveryFrontStatus.Accepted && delivery.typeDelivery === DeliveryType.Onetime) {
      return 'deliveries/today/onetime';
    }

    if (deliveryFrontStatus === DeliveryFrontStatus.ToComplete) {
      return isMobile ? 'deliveries/toaccept' : 'deliveries/tocomplete';
    }

    if (deliveryFrontStatus === DeliveryFrontStatus.ToAccept) {
      return 'deliveries/toaccept';
    }

    return 'deliveries/all';
  }

  /**
   * Closing panel of delivery details preview panel.
   *
   * @param isUserAction <boolean>
   * Flag indicating if panel was closed by using escape
   *  or 'cancel' or 'close'.
   *  False - panel was closed by going to ddifferent route.
   *  True - panel was closed by intentional 'close' action of user.
   *
   */
  closeDeliveryDetails(isUserAction: boolean = false) {
    this.panelOpened = false;
    this.toggleDeliveryDetailsSource.next({ opened: this.panelOpened, isUserAction });

    if (isUserAction) {
      this.router.navigate([], { queryParams: { id: null } });
    }
  }

  toggleLongtimeDelivery(panelState: LongtermPanelState) {
    this.panelOpened = panelState.opened;
    this.toggleAddLongtermDeliverySource.next(panelState);
    this.closeRejectDeliveryAside();
  }

  toggleDeliveryDetails(details: DeliveryDetails) {
    const deliveryTypesFromConfig = Object.values(config.typeDelivery);

    if (!deliveryTypesFromConfig.includes(details.type)) {
      return;
    }

    this.panelOpened = details.opened;
    this.toggleDeliveryDetailsSource.next(details);
    this.closeRejectDeliveryAside();
  }

  proposalDateSelected(date: string) {
    this.proposalDateSource.next(date);
  }

  getQueryForTodayLongTerm(): QueryParamObject[] {
    return [
      {
        type: QueryParams.Filter,
        filterField: 'deliveryStage.date',
        condition: 'gte',
        value: moment().startOf('d').valueOf(),
      },
      {
        type: QueryParams.Filter,
        filterField: 'deliveryStage.date',
        condition: 'lte',
        value: moment().endOf('d').valueOf(),
      },
    ];
  }

  mapStage(stage: DeliveryStage): DeliveryStage {
    const level = this.translateService.instant('deliveries_page.floor_abbr_l');
    const materialName = get(stage, 'material.name', '');
    const materialDestination = get(stage, 'materialDestination.name', '');
    // eslint-disable-next-line max-len
    const label = `${stage.code} - ${materialName} - ${materialDestination} - ${level}:${stage.buildingFloorFrom}÷${stage.buildingFloorTo}`;
    return { ...stage, label };
  }

  public mapMaterials(deliveryMaterials: DeliveryMaterial[]): MaterialMappedForRequest[] {
    const mapMaterial = (deliveryMaterial: DeliveryMaterial): MaterialMappedForRequest => ({
      id: deliveryMaterial.id,
      categoryTranslatedName: get(deliveryMaterial, 'materialCategory.translatedName', null),
      materialTranslatedName: get(deliveryMaterial, 'material.translatedName', null),
      identifier: get(deliveryMaterial, 'material.marIdentifier', null),
      expectedAmount: deliveryMaterial.expectedAmount,
      deliveredAmount: deliveryMaterial.deliveredAmount,
      unit: deliveryMaterial.unit,
      producerName: get(deliveryMaterial, 'producer.name', null),
      producerId: get(deliveryMaterial, 'producer.id', null),
      supplierId: deliveryMaterial.supplierId,
      inflammable: deliveryMaterial.inflammable,
      combined: deliveryMaterial.combined,
      description: get(deliveryMaterial, 'material.description', null),
      import: deliveryMaterial.direction === 'import' || deliveryMaterial.direction === 'both',
      export: deliveryMaterial.direction === 'export' || deliveryMaterial.direction === 'both',
      palletized: deliveryMaterial.palletized,
      weight: deliveryMaterial.weight || null,
      inspectionID: get(deliveryMaterial, ['deliveryMaterialInspections', 0, 'inspectionRequestIdentifier'], null),
      remarks: deliveryMaterial.remarks,
    });

    return deliveryMaterials?.map(mapMaterial) || [];
  }

  public setMaterialsValidators(controlsArr: (GenericFormGroup<MaterialMappedForRequest> | AbstractControl)[]): void {
    controlsArr.forEach((group) => {
      const deliveredControl = group.get('deliveredAmount');
      const expectedControl = group.get('expectedAmount');
      const weightControl = group.get('weight');

      deliveredControl.setValidators([Validators.min(0), Validators.max(999999.99)]);
      deliveredControl.updateValueAndValidity();

      expectedControl.setValidators([Validators.min(0), Validators.max(999999.99)]);
      expectedControl.updateValueAndValidity();

      weightControl.setValidators([Validators.min(0), Validators.max(99999)]);
      weightControl.updateValueAndValidity();
    });
  }

  public openStagePanel(delivery: Delivery, stage: DeliveryStage, deliveryStatus?: string, stageId?: string) {
    this.deliveryOfStage = { ...delivery };
    this.stage = { ...stage };
    this.deliveryStatus = deliveryStatus;
    this.stageId = stageId;
    this.stagePanelOpenedSubject.next(true);
    this.closeRejectDeliveryAside();
  }

  public closeStagePanel() {
    this.stagePanelOpenedSubject.next(false);
    this.deliveryOfStage = null;
    this.stage = null;
  }

  // send-truck-panel is using this.deliveryOfStage & this.stage
  public openSendTruckPanel() {
    this.sendTruckPanelOpenedSubject.next(true);
    this.closeRejectDeliveryAside();
  }

  public closeSendTruckPanel() {
    this.sendTruckPanelOpenedSubject.next(false);
  }

  public openDeliveryRunPanel(runPanelConfig: RunPanelConfig) {
    this.runConfig = runPanelConfig;
    this.deliveryRunPanelOpenedSubject.next(runPanelConfig);
    this.closeRejectDeliveryAside();
  }

  public closeDeliveryRunPanel() {
    this.deliveryRunPanelOpenedSubject.next({ opened: false });
  }

  public openRejectDeliveryAside(data: { id: number; deliveryId: string; redirectAfter?: boolean }): void {
    this.closeDeliveryDetails(true);
    this.rejectDeliveryAsideOpened$.next({ ...data, opened: true });
  }

  public closeRejectDeliveryAside(): void {
    this.rejectDeliveryAsideOpened$.next({ id: null, deliveryId: null, opened: false });
  }

  public openOnetimeDeliveryDuplicateAside(delivery: Delivery, redirectToConstructionPlan: boolean = false): void {
    this.onetimeDeliveryData = delivery;

    if (redirectToConstructionPlan) {
      this.router.navigate(['construction-plan', 'current'], { fragment: 'add-delivery' });
      return;
    }

    this.openOnetimeDeliveryForm();
  }

  public clearOnetimeDeliveryData(): void {
    this.onetimeDeliveryData = null;
  }

  public openOnetimeDeliveryForm(): void {
    const isPermission = this.grantedPermissions.includes(PermissionsEnum.DELIVERIES_ONETIME_ADD);

    if (!isPermission) {
      return;
    }

    this.onetimeDeliveryFormOpened$.next({ opened: true });
  }

  public closeOnetimeDeliveryForm(isUserAction = false): void {
    this.onetimeDeliveryFormOpened$.next({ opened: false, isUserAction });
  }
}
